import { useState } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

const MyNavbar = ({ title }) => {
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className='d-flex justify-content-space-between align-items-center bg-light' style={{ padding: 15 }}>
            <div id="dashboard-navbar-brand">{title}</div>
            <div className='d-flex align-items-center'>
                <div className={title === 'Dashboard' ? 'd-none d-lg-block me-10' : 'd-none d-lg-block me-4'}>
                    <div className="d-flex align-items-center">
                        <div style={{
                            display: "flex",
                            minWidth: "178px",
                            paddingRight: "0px",
                            alignItems: "center"
                        }}>
                            <Form.Control
                                type="text"
                                placeholder="Search here"
                                className="make-beautify me-2"
                                aria-label="Search"
                                style={{ minWidth: 300, paddingRight: 30, caretColor: '#008181' }}
                            />
                            <div style={{ position: "relative", right: 35, top: 0 }}>
                                <Image src='/images/icons/search.png' width={14} height={14} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='p-relative'>
                    <span className='notify'></span>
                    <Button className='text-dark me-2 make-beautify' as={Link} to={"#"} style={{ lineHeight: "1.5rem", background: "white", border: "0px solid" }}>
                        <Image src='/images/icons/chat.png' width={16} height={16} />
                    </Button>
                </div>
                <div className='p-relative'>
                    <span className='notify'></span>
                    <Button className='text-dark me-2 make-beautify' as={Link} to={"#"} style={{ lineHeight: "1.5rem", background: "white", border: "0px solid" }}>
                        <Image src='/images/icons/bell.png' width={18} height={18} />
                    </Button>
                </div>
                {(title === 'Rooms' || title === 'Booking' || title === 'Guest List' || title === 'Employee' || title === 'Settings' || title === 'Guest Detail' || title === 'Reviews') &&
                    <div className={`p-relative ${(title === 'Rooms' || title === 'Booking' || title === 'Guest List' || title === 'Employee' || title === 'Settings' || title === 'Guest Detail' || title === 'Reviews') ? 'my-me-5' : ''}`}>
                        <span className='notify'></span>
                        <Button className='text-dark me-2 make-beautify' as={Link} to={"#"} style={{ lineHeight: "1.5rem", background: "white", border: "0px solid" }}>
                            <Image src='/images/icons/love.png' width={16} height={16} />
                        </Button>
                    </div>
                }
                {title !== 'Rooms' && title !== 'Booking' && title !== 'Guest List' && title !== 'Employee' && title !== 'Settings' && title !== 'Guest Detail' && title !== 'Reviews' &&
                    <div className='me-2 d-none d-lg-block'>
                        <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className='navbar-datepicker'
                            dateFormat="MMMM d, yyyy"
                        />
                    </div>
                }

                {(title === 'Guest List' || title === 'Rooms' || title === 'Booking' || title === 'Employee' || title === 'Settings' || title === 'Guest Detail' || title === 'Reviews') &&
                    <div className='d-none d-lg-block'>
                        <div className="d-flex me-3">
                            <Image src="/images/boy-1.jpg" width={35} height={35} rounded className='me-1' />
                            <div className='d-flex align-items-center' style={{ flexDirection: "column" }}>
                                <strong style={{ fontSize: 14 }}>John Doe</strong>
                                <span style={{ fontSize: 12 }}>Hotel Owner</span>
                            </div>
                        </div>
                    </div>
                }

                <Dropdown drop='down' style={{ background: "white", borderRadius: 5 }} className='language-dropdown'>
                    <Dropdown.Toggle className='make-beautify' style={{ background: "white", color: "black", border: "0px solid", borderRadius: 10, fontWeight: 500 }}>EN</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#">English</Dropdown.Item>
                        <Dropdown.Item href="#">Arabic</Dropdown.Item>
                        <Dropdown.Item href="#">Urdu</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default MyNavbar;