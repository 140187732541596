import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Image,
  Row,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDropzone } from "react-dropzone";
import { FaInfoCircle } from "react-icons/fa";
import {
  API_BASE_URL,
  CREATE_HOTEL_ENDPOINT,
  UPDATE_HOTEL_ENDPOINT,
} from "../../../endpoints";
import { doUpload } from "../../../helpers/uploads";
import ReactDatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";
import {
  DEFAULT_CHILDREN_POLICY,
  DEFAULT_EXTRA_BEDS_POLICY,
  DEFAULT_GUEST_POLICY,
  DEFAULT_OTHERS_POLICY,
} from "../../../config/constant";
import { apiPatchWithAuth, apiPostWithAuth } from "../../../hooks/authService";

export default function Add(props) {
  const token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
  const [isUploading, setIsUploading] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [validated, setValidated] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [addShowModal, setAddShowModal] = useState(false);

  const initialFormModalState = {
    userId: JSON.parse(localStorage.getItem("user"))?.id,
    thumbnails: [],
    name: "",
    email: "",
    roomType: "single",
    numberOfGuests: "",
    arrivalDateTime: { date: "", time: 32400 },
    departureDate: { date: "", time: 32400 },
    pickup: "yes",
    status: "pending",
    specialRequest: "",
    guestPolicy: DEFAULT_GUEST_POLICY,
    childrenPolicy: DEFAULT_CHILDREN_POLICY,
    extraBedsPolicy: DEFAULT_EXTRA_BEDS_POLICY,
    others: DEFAULT_OTHERS_POLICY,
    city: "",
    destinationId: "607f191e810c19729de860ea",
    description: "",
  };

  const [formModal, setFormModal] = useState(initialFormModalState);

  const handleCloseModal = () => {
    setAddShowModal(false);
    props.onHide();
    resetModal();
  };

  useEffect(() => {
    getDestinations();
  }, []);

  useEffect(() => {
    if (props.show) {
        setAddShowModal(true); // Show the modal

        if (props.hotelData) {
          
            // Populate the form with hotel data when editing
            setFormModal({
                ...initialFormModalState,
                ...props.hotelData,
                arrivalDateTime: {
                    date: new Date(props.hotelData.arrivalDateTime.date),
                    time: props.hotelData.arrivalDateTime.time,
                },
                departureDate: {
                    date: new Date(props.hotelData.departureDate.date),
                    time: props.hotelData.departureDate.time,
                },
                thumbnails: props.hotelData.thumbnails || [],
            });
        } else {
            // Reset form state if no hotel data is provided
            setFormModal(initialFormModalState);
        }
    } else {
        resetModal(); // Reset the modal if it's closed
    }
}, [props.show, props.hotelData]);

  const resetModal = () => {
    setApiResponse({});
    setAddShowModal(false);
    setFormModal(initialFormModalState);
  };

  const onDrop = useCallback(async (selectedFiles) => {
    const uploadFile = async (file) => {
      try {
        const result = await doUpload(file);
        return result;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const uploadedFiles = await Promise.all(selectedFiles.map(uploadFile));
    const successfulUploads = uploadedFiles.filter((result) => result !== null);

    setFormModal((prev) => ({
      ...prev,
      thumbnails: [...prev.thumbnails, ...successfulUploads],
    }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  // Create hotel handler
  const createHotelHandler = async (formModal) => {
    try {
      console.log("Creating hotel", formModal);
      const res = await apiPostWithAuth(
        CREATE_HOTEL_ENDPOINT,
        formModal,
        "POST"
      );
      console.log("Response from create hotel", res);

      if (res.status === 200 || res.status === 201) {
        setApiResponse({
          type: "success",
          message: "Hotel created successfully!",
        });
        handleCloseModal();
      }
    } catch (err) {
      setApiResponse({
        type: "danger",
        message: err.response?.data?.message || "Failed to create the hotel.",
      });
      console.error("Error occurred during hotel creation:", err);
    }
  };

  // Update hotel handler
  const updateHotelHandler = async (formModal, hotelId) => {
    try {
      console.log("Updating hotel", formModal);
      const updateUrl = `${UPDATE_HOTEL_ENDPOINT(hotelId)}`;
      const res = await apiPatchWithAuth(updateUrl, formModal, "PATCH"); // Send PATCH request

      if (res.status === 200 || res.status === 201) {
        setApiResponse({
          type: "success",
          message: "Hotel updated successfully!",
        });
        handleCloseModal(); // Reset modal or form after successful update
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Failed to update the hotel.";
      setApiResponse({ type: "danger", message: errorMessage });
      console.error("Error occurred during hotel update:", err);
    }
  };

  const onSubmitHandle = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (
      form.checkValidity() === false ||
      !formModal.numberOfGuests ||
      !formModal.arrivalDateTime.date ||
      !formModal.arrivalDateTime.time ||
      !formModal.departureDate.date ||
      !formModal.departureDate.time ||
      formModal.thumbnails.length === 0
    ) {
      setValidated(true);
      return;
    }

    console.log("Form modal before submission:", JSON.stringify(formModal, null, 2));

    // If we have hotelData, perform an update
    if (props.hotelData && props.hotelData.id) {
      const hotelId = props.hotelData.id; // Retrieve the hotel ID from hotelData

      // Destructure and remove fields that should not be part of the update
      const { id, discountId, reviews, guestPolicy, childrenPolicy, extraBedsPolicy, others, ...allowedFormModal } = formModal;

      // Ensure discountId is a string if it’s part of the data
      if (allowedFormModal.discountId && typeof allowedFormModal.discountId !== "string") {
        allowedFormModal.discountId = String(allowedFormModal.discountId);
      }

      console.log("Updating hotel with data:", allowedFormModal);
      await updateHotelHandler(allowedFormModal, hotelId);
    } else {
      // Create new hotel if no hotelData exists
      await createHotelHandler(formModal);
    }
};

  const getDestinations = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}destinations`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        setDestinations(res.data.destinations);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleCloseModal} // Ensure the modal calls the close handler
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.hotelData ? "Update Hotel" : "Add Hotel"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {apiResponse && Object.keys(apiResponse).length > 0 && (
          <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>
        )}
        <Form noValidate validated={validated} onSubmit={onSubmitHandle}>
          <Row className="p-3">
            <Col xs={12} md={12} className="mb-2">
              <FormGroup>
                <FormLabel>
                  Select Photo <span className="required">{" * "}</span>
                  <FaInfoCircle color="#e5e5e5" />
                </FormLabel>
                <div
                  {...getRootProps({
                    className: `dropzone ${
                      validated && formModal.thumbnails.length === 0
                        ? "invalid"
                        : ""
                    }`,
                  })}
                >
                  <input {...getInputProps()} />
                  {formModal.thumbnails && formModal.thumbnails.length === 0 ? (
                    <Row>
                      <Col xs={12} md={4} style={{ textAlign: "left" }}>
                        <Image
                          src="./../images/no-image.png"
                          width={119}
                          height={120}
                        />
                      </Col>
                      <Col xs={12} md={8}>
                        <span className="text-muted">
                          Drag & Drop <br /> <span>OR</span> <br />{" "}
                          <span className="text-danger">Browse Photo</span>{" "}
                          <br />
                          <br /> Supports: *.png, *.jpg and *.jpeg
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="d-flex" style={{ overflow: "auto" }}>
                        {formModal.thumbnails.map((thumbnail, key) => (
                          <div key={key}>
                            <Image
                              src={thumbnail}
                              width={119}
                              height={120}
                              style={{
                                border: "1px solid #e5e5e5",
                                borderRadius: 15,
                                marginRight: 10,
                              }}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col xs={12} md={6} className="mb-2">
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Full Name"
                  value={formModal.name}
                  onChange={(e) =>
                    setFormModal({ ...formModal, name: e.target.value })
                  }
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6} className="mb-2">
              <FormGroup>
                <FormLabel>
                  Email <span className="required">{" * "}</span>
                  <FaInfoCircle color="#e5e5e5" />
                </FormLabel>
                <FormControl
                  type="email"
                  placeholder="jhon@example.com"
                  required
                  value={formModal.email}
                  onChange={(e) =>
                    setFormModal({ ...formModal, email: e.target.value })
                  }
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  Destination <span className="required">{" * "}</span>
                  <FaInfoCircle color="#e5e5e5" />
                </FormLabel>
                <FormSelect
                  value={formModal.destinationId}
                  onChange={(e) =>
                    setFormModal({
                      ...formModal,
                      destinationId: e.target.value,
                    })
                  }
                >
                  {destinations &&
                    destinations.length > 0 &&
                    destinations.map((dest, key) => (
                      <option key={key} value={dest.id}>
                        {dest.name}
                      </option>
                    ))}
                </FormSelect>
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  City <span className="required">{" * "}</span>
                  <FaInfoCircle color="#e5e5e5" />
                </FormLabel>
                <FormControl
                  type="text"
                  placeholder="Lahore"
                  required
                  value={formModal.city}
                  onChange={(e) =>
                    setFormModal({ ...formModal, city: e.target.value })
                  }
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6} className="mb-2">
              <FormGroup>
                <FormLabel>Room Type</FormLabel>
                <FormSelect
                  value={formModal.roomType}
                  onChange={(e) =>
                    setFormModal({ ...formModal, roomType: e.target.value })
                  }
                >
                  <option value="Single">Single</option>
                  <option value="Double">Double</option>
                  <option value="Triple">Triple</option>
                  <option value="All">All</option>
                  <option value="Others">Others</option>
                </FormSelect>
              </FormGroup>
            </Col>

            <Col xs={12} md={6} className="mb-2">
              <FormGroup>
                <FormLabel>
                  No#. of Guests <span className="required">{" * "}</span>
                </FormLabel>
                <FormControl
                  type="number"
                  placeholder="Select No#. of guests"
                  required
                  value={formModal.numberOfGuests}
                  onChange={(e) =>
                    setFormModal({
                      ...formModal,
                      numberOfGuests: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Col>

            <Col xs={12} md={6} className="mb-2">
              <FormGroup hasValidation>
                <FormLabel>
                  Arrival Date & Time <span className="required">{" * "}</span>
                </FormLabel>
                <Row>
                  <Col xs={12} md={8}>
                    <ReactDatePicker
                      selected={formModal.arrivalDateTime.date}
                      onChange={(date) =>
                        setFormModal({
                          ...formModal,
                          arrivalDateTime: {
                            ...formModal.arrivalDateTime,
                            date: date,
                          },
                        })
                      }
                      dateFormat="MMM d / yyyy"
                      className={`form-control ${
                        validated &&
                        !formModal.arrivalDateTime.date &&
                        "invalid"
                      }`}
                      placeholderText="Aug 26 / 2024"
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <TimePicker
                      start="09:00"
                      end="18:00"
                      step={30}
                      value={formModal.arrivalDateTime.time}
                      onChange={(time) =>
                        setFormModal({
                          ...formModal,
                          arrivalDateTime: {
                            ...formModal.arrivalDateTime,
                            time,
                          },
                        })
                      }
                      format={12}
                      className={`form-control ${
                        validated &&
                        !formModal.arrivalDateTime.time &&
                        "invalid"
                      }`}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col xs={12} md={6} className="mb-2">
              <FormGroup hasValidation>
                <FormLabel>
                  Departure Date <span className="required">{" * "}</span>
                </FormLabel>
                <Row>
                  <Col xs={12} md={8}>
                    <ReactDatePicker
                      selected={formModal.departureDate.date}
                      onChange={(date) =>
                        setFormModal({
                          ...formModal,
                          departureDate: {
                            ...formModal.departureDate,
                            date: date,
                          },
                        })
                      }
                      dateFormat="MMM d / yyyy"
                      className={`form-control ${
                        validated && !formModal.departureDate.date && "invalid"
                      }`}
                      placeholderText="Aug 30 / 2024"
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <TimePicker
                      start="09:00"
                      end="18:00"
                      step={30}
                      value={formModal.departureDate.time}
                      onChange={(time) =>
                        setFormModal({
                          ...formModal,
                          departureDate: { ...formModal.departureDate, time },
                        })
                      }
                      format={12}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col md={6} xs={12}>
              <FormGroup>
                <FormLabel>Pickup</FormLabel>
                <FormSelect
                  value={formModal.pickup}
                  onChange={(e) =>
                    setFormModal({ ...formModal, pickup: e.target.value })
                  }
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </FormSelect>
              </FormGroup>
            </Col>

            <Col md={6} xs={12}>
              <FormGroup>
                <FormLabel>Status</FormLabel>
                <FormSelect
                  value={formModal.status}
                  onChange={(e) =>
                    setFormModal({ ...formModal, status: e.target.value })
                  }
                >
                  <option value="available">Available</option>
                  <option value="pending">Pending</option>
                  <option value="canceled">Canceled</option>
                  <option value="booked">Booked</option>
                  <option value="refund">Refund</option>
                </FormSelect>
              </FormGroup>
            </Col>
            <Col xs={12} md={12} className="mt-2">
              <FormGroup>
                <FormLabel>Special Request</FormLabel>
                <FormControl
                  value={formModal.specialRequest}
                  onChange={(e) =>
                    setFormModal({
                      ...formModal,
                      specialRequest: e.target.value,
                    })
                  }
                  as="textarea"
                  rows={3}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={12} className="mt-2">
              <FormGroup>
                <FormLabel>Description</FormLabel>
                <FormControl
                  value={formModal.description}
                  onChange={(e) =>
                    setFormModal({ ...formModal, description: e.target.value })
                  }
                  as="textarea"
                  rows={3}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <Button
              variant="outline-light"
              className="text-dark"
              onClick={handleCloseModal}
            >
              Discard
            </Button>
            <Button
              variant="danger"
              className="bg-custom-variant"
              type="submit"
            >
              {props.hotelData ? "Update Hotel" : "Add Hotel"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
