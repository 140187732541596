import 'bs5-lightbox';
import { Image, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
const TopDestination = ({ topDestinations }) => {
    return (
        <div>
            {/* <h5 className='mb-2 fontWeight-600'>Top Destination</h5> */}
            <Row>
                {topDestinations && topDestinations.length > 0 && topDestinations.map((dest, key) => (
                    <div className={`${(key === 0 || key === 5) ? 'col-12 col-md-6 mb-3 mb-md-0' : 'col-6 col-md-3 mb-3'}`} key={key}>
                        <Link to="#" data-toggle="lightbox" data-gallery="example-gallery" style={{ position: 'relative', display: 'block' }}>
                            <Image src={dest.thumbnail} className="img-fluid" />
                            <span style={{ position: 'absolute', top: 15, left: 15, background: 'transparent', padding: '5px', color: 'white', letterSpacing: 0, fontWeight: 700, fontSize: 16 }}>
                                {dest.name}
                                {/* <hr style={{marginLeft: 5 , marginRight: 15}}/> */}
                            </span>
                        </Link>
                    </div>
                ))}
            </Row>
        </div>
    );
}

const mapStateToProps = (state) => ({
    topDestinations: state.landingPagesInitialState.topDestinations
});

export default connect(mapStateToProps, {})(TopDestination);
