import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'; // Add useCallback import
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// Create UserContext
export const UserContext = createContext();

// Custom hook to use UserContext
export const useUser = () => {
    return useContext(UserContext);
};

// Create UserProvider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate

    // Define logout using useCallback
    const logout = useCallback(() => { 
        setUser(null);
        setAccessToken(null);
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/'); // Navigate to the landing/home route after logout
    }, [navigate]); // Add navigate as a dependency

    // useEffect to check access token and user
    useEffect(() => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedAccessToken) {
            const decodedToken = jwtDecode(storedAccessToken);
            const isExpired = decodedToken.exp * 1000 < Date.now();
            if (!isExpired) {
                setAccessToken(storedAccessToken);
                setUser(storedUser);
            } else {
                logout(); // If token is expired, log the user out
            }
        }
        setLoading(false);
    }, [logout]); // Ensure logout is added here

    const login = (userData, tokens) => {
        setUser(userData);
        setAccessToken(tokens.access.token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('accessToken', tokens.access.token);
        localStorage.setItem('refreshToken', tokens.refresh.token);
        console.log("User logged in:", userData);
        console.log("Access Token set:", tokens.access.token);
    };

    return (
        <UserContext.Provider value={{ user, accessToken, login, logout, loading }}>
            {!loading && children}  {/* Render children only after loading */}
        </UserContext.Provider>
    );
};
