import { Navigate, Routes, Route } from "react-router-dom";
import AuthProtected from "./AuthProtected";
import RoleProtected from "./RoleProtected";
import AuthLayout from "../components/Layouts/AuthLayout";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import Login from "../components/login/login";
import Signup from "../components/signup/singup";
import Dashboard from '../components/dashboard/dashboard';
import LandingLayout from "../components/Layouts/LandingLayout";
import Home from "../components/home/home";
import AllHotels from "../components/all-hotels/all-hotels";
import AboutUs from "../components/about-us/about-us";
import ContactUs from "../components/contact-us/contact-us";
import Cart from "../components/cart/cart";
import PlaceOrder from "../components/cart/place-order";
import HotelDetail from "../components/all-hotels/hotel-detail";
import Reviews from "../components/dashboard/reviews";
import Rooms from "../components/dashboard/rooms";
import Employee from "../components/dashboard/employee";
import Booking from "../components/dashboard/booking";
import Settings from "../components/dashboard/settings";
import SearchResults from "../components/dashboard/booking/search-results";
import BookingHotelDetail from "../components/dashboard/booking/detail";
import Tour from "../components/tour";
import TourDetail from "../components/tour/detail";
import Hotels from "../components/dashboard/hotels";
import Logout from "../components/logout";
import GuestList from "../components/dashboard/guest";
import GuestDetail from "../components/dashboard/guest/detail";
import VerifyEmailScreen from "../components/verify-email/verify-email";
import { useUser } from "../userContext";

export default function AppRoutes() {
    let { user, isLoggedIn } = useUser(); 
    let isAdmin = false;

    if (user && Object.keys(user).length > 0) {
        isLoggedIn = true;
        isAdmin = user.role === 'admin';
    }

    return (
            <Routes>
                {/* Landing Routes */}
                <Route element={<LandingLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/all-hotels" element={<AllHotels />} />
                    <Route path="/tour" element={<Tour />} />
                    <Route path="/tour-detail" element={<TourDetail />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/place-order" element={<PlaceOrder />} />
                    <Route path="/hotel-detail/:hotelId" element={<HotelDetail />} />
                    <Route path="/verify-email" element={<VerifyEmailScreen />} />
                </Route>

                {/* Authentication Routes */}
                <Route element={<AuthLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                </Route>

                {/* Protected Routes */}
                <Route element={<AuthProtected user={user} isLoggedIn={isLoggedIn} />}>
                    <Route element={<DashboardLayout />}>
                        {/* Redirect based on role */}
                        <Route path="/dashboard" element={isAdmin ? <Navigate to="/dashboard/home" /> : <Navigate to="/" />} />
                        <Route path="/dashboard/home" element={isAdmin ? <Dashboard /> : <Navigate to="/" />} />
                        <Route path="/dashboard/reviews" element={isAdmin ? <Reviews /> : <Navigate to="/" />} />
                        <Route path="/dashboard/guest-list" element={isAdmin ? <GuestList /> : <Navigate to="/" />} />
                        <Route path="/dashboard/guest-detail/:userId" element={isAdmin ? <GuestDetail /> : <Navigate to="/" />} />
                        <Route path="/dashboard/rooms" element={isAdmin ? <Rooms /> : <Navigate to="/" />} />
                        <Route path="/dashboard/employee" element={isAdmin ? <Employee /> : <Navigate to="/" />} />
                        <Route path="/dashboard/booking" element={isAdmin ? <Booking /> : <Navigate to="/" />} />
                        <Route path="/dashboard/hotels" element={isAdmin ? <Hotels /> : <Navigate to="/" />} />
                        <Route path="/dashboard/search-results" element={isAdmin ? <SearchResults /> : <Navigate to="/" />} />
                        <Route path="/dashboard/search-hotel-detail" element={isAdmin ? <BookingHotelDetail /> : <Navigate to="/" />} />
                        <Route path="/dashboard/settings" element={isAdmin ? <Settings /> : <Navigate to="/" />} />
                        <Route path="/dashboard/logout" element={<Logout />} />
                        <Route path="/dashboard/inventory" element={<RoleProtected user={user} allowedRoles={['admin', 'manager', 'member']}><Reviews /></RoleProtected>} />
                    </Route>
                </Route>

                {/* Fallback route for undefined paths */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
    );
}
