import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormControl, FormGroup, FormLabel, FormSelect, Image, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import { FaInfoCircle } from 'react-icons/fa';
import { API_BASE_URL, API_REQUEST_HEADER, GET_HOTELS_ENDPOINT } from '../../../endpoints';
import { doUpload } from '../../../helpers/uploads';
import ReactDatePicker from 'react-datepicker';
import { apiGetWithAuth } from '../../../hooks/authService';

export default function Add(props) {
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [hotels, setHotels] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [apiResponse, setApiResponse] = useState({});
    const [validated, setValidated] = useState(false);
    const [formModal, setFormModal] = useState({
        userId: userId,
        avatar: '',
        name: '',
        email: '',
        roomType: 'single',
        checkIn: new Date(),
        checkOut: new Date(),
        password: 'password1',
        phoneNumber: '',
        role: 'guest',
        code: '',
        hotelId: '',
        roomId: ''
    });

    useEffect(() => {
        if (!props.show) {
            setApiResponse({});
            setValidated(false);
        }
        getHotels();
    }, [props.show]);

    const onDrop = useCallback(async (selectedFiles) => {
        const uploadFile = async (file) => {
            try {
                const result = await doUpload(file);
                setFormModal({ ...formModal, avatar: result });
            } catch (error) {
                setApiResponse(error);
                console.error(error);
            }
        };
        for (const file of selectedFiles) {
            await uploadFile(file);
        }
    }, [formModal]);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
    });

    const onSubmitHandle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false || !formModal.avatar) {
            setValidated(true);
            return;
        }
        axios.post(`${API_BASE_URL}auth/register`, formModal, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 201) {
                setApiResponse({ type: "success", message: "New Guest created successfully!" });
                setFormModal({
                    userId: userId,
                    avatar: '',
                    name: '',
                    email: '',
                    roomType: 'single',
                    checkIn: '',
                    checkOut: '',
                    password: 'password1',
                    phoneNumber: '',
                    role: 'guest',
                    code: '',
                    hotelId: '',
                    roomId: ''
                });
            }
        }).catch(err => {
            setApiResponse({ type: "danger", message: err?.response?.data?.message });
            console.log(err);
        });
    }
    const getHotels = () => {
        apiGetWithAuth(GET_HOTELS_ENDPOINT(userId, 1000, 1), API_REQUEST_HEADER()).then((response) => {
            if (response?.status === 200) {
                setHotels(response?.data?.hotels);
                if (response.data && response.data.hotels && response.data.hotels.length > 0) {
                    setFormModal({ ...formModal, hotelId: response?.data.hotels[0]?.id });
                }
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    const getRooms = (id) => {
        axios.get(`${API_BASE_URL}rooms/get-rooms-by-hotel-id/${id}`, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res.status === 200) {
                setRooms(res.data);
                if (res.data && res.data && res.data.length > 0) {
                    setFormModal(prevFormModal => ({
                        ...prevFormModal,
                        roomId: res?.data[0]?.id
                    }));
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (formModal.hotelId) {
            getRooms(formModal.hotelId);
        }
    }, [formModal.hotelId]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={onSubmitHandle}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Guest
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
                    <Row className='p-3'>
                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Select Photo <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <div {...getRootProps({ className: `dropzone ${validated && !formModal.avatar ? 'invalid' : ''}` })}>
                                    <input {...getInputProps()} />
                                    {!formModal.avatar ?
                                        <Row>
                                            <Col xs={12} md={4} style={{ textAlign: "left" }}>
                                                <Image src="./../images/no-image.png" width={119} height={120} />
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <span className='text-muted'>
                                                    Drag & Drop <br /> <span>OR</span> <br /> <span className='text-danger'>Browse Photo</span> <br /><br /> Supports: *.png, *.jpg and *.jpeg
                                                </span>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className='d-flex' style={{ overflow: 'auto' }}>
                                                {formModal.avatar &&
                                                    <Image src={formModal.avatar} width={119} height={120} style={{ border: '1px solid #e5e5e5', borderRadius: 15, marginRight: 10 }} />
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Hotels</FormLabel>
                                <FormSelect required value={formModal.hotelId} onChange={(e) => { setFormModal({ ...formModal, hotelId: e.target.value }); getRooms(e.target.value) }}>
                                    {hotels && hotels.length > 0 && hotels.map((hotel, key) => (
                                        <option value={hotel.id} key={key}>{hotel.name}</option>
                                    ))}
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Rooms</FormLabel>
                                <FormSelect required value={formModal.roomId} onChange={(e) => setFormModal({ ...formModal, roomId: e.target.value })}>
                                    <option>--select a room--</option>
                                    {rooms && rooms.length > 0 && rooms.map((room, key) => (
                                        <option value={room.id} key={key}>{room.title}</option>
                                    ))}
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Name</FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Full Name'
                                    value={formModal.name}
                                    onChange={(e) => setFormModal({ ...formModal, name: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Code</FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Guest code'
                                    value={formModal.code}
                                    onChange={(e) => setFormModal({ ...formModal, code: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Number <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="phone"
                                    placeholder='0234421211'
                                    value={formModal.phoneNumber}
                                    required
                                    onChange={(e) => setFormModal({ ...formModal, phoneNumber: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Email <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="email"
                                    placeholder='jhon@example.com'
                                    required
                                    value={formModal.email}
                                    onChange={(e) => setFormModal({ ...formModal, email: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Room Type</FormLabel>
                                <FormSelect placeholder='Select Room Type' value={formModal.roomType} onChange={(e) => setFormModal({ ...formModal, roomType: e.target.value })}>
                                    <option value='single'>Single</option>
                                    <option value='double'>Double</option>
                                    <option value='triple'>Triple</option>
                                    <option value='others'>other</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Check in Date</FormLabel>
                                <ReactDatePicker
                                    selected={formModal.checkIn}
                                    onChange={(date) => setFormModal({ ...formModal, checkIn: date })}
                                    dateFormat="MMM d / yyyy"
                                    className={`form-control ${validated && !formModal.checkIn && 'invalid'}`}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Check out Date</FormLabel>
                                <ReactDatePicker
                                    selected={formModal.checkOut}
                                    onChange={(date) => setFormModal({ ...formModal, checkOut: date })}
                                    dateFormat="MMM d / yyyy"
                                    className={`form-control ${validated && !formModal.checkOut && 'invalid'}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Button variant="outline-light" className='text-dark' onClick={props.onHide}>Discard</Button>
                    <Button variant='danger' className='bg-custom-variant' type="submit">Add Guest</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}