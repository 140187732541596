import 'bs5-lightbox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const TrendingRental = () => {
    return (
        <>
            {/* <div className='d-flex justify-content-space-between align-items-center'>
                <h5 style={{ fontWeight: 700 }}>Trending Rental</h5>
                <Link to="#" className='custom-text-color fontWeight-600'>
                    More <AiOutlineArrowRight />
                </Link>
            </div>

            <Row className='mb-3'>
                {trendingRental && trendingRental.length > 0 && trendingRental.map((item, key) => {
                    return (
                        <Col xs={6} md={3} key={key} className="mb-3">
                            <Link to={"/tour-detail"} className="text-decoration-none">
                                <SearchResultSingleItem imgUrl={item.thumbnail} requestFrom='trendingRental' item={item} />
                            </Link>
                        </Col>
                    );
                })}
            </Row> */}
        </>
    );
}

export default TrendingRental;
