import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import RenderRadioButtonText from "../includes/RenderRadioButtonText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { API_BASE_URL } from "../../endpoints";
import { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import { useUser } from "../../userContext";
import { toast, ToastContainer } from "react-toastify";

const PlaceOrder = ({ userCoupon, setUserCoupon, resetCart }) => {
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState("Rs");
  const bookingData = location.state?.bookingData || {};
  const { user } = useUser();
  const [formModal, setFormModal] = useState({
    firstname: "",
    lastname: "",
    companyName: "",
    countryRegion: "",
    streetAddress: "",
    cityOrTown: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
    paymentMethod: "CashOnDelivery",
  });
  const [isFormComplete, setIsFormComplete] = useState(false);
  let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
  const [inputCouponValue, setInputCouponValue] = useState("");
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isFirstAttempt, setIsFirstAttempt] = useState(true); // Track if it’s the first booking attempt

  useEffect(() => {
    console.log("User from context:", user); // Debug: Ensure `user` is available
  }, [user]);

  const checkFormCompletion = () => {
    const isComplete =
      formModal.firstname &&
      formModal.lastname &&
      formModal.streetAddress &&
      formModal.cityOrTown &&
      formModal.state &&
      formModal.zipCode &&
      formModal.phoneNumber &&
      formModal.email;
    setIsFormComplete(isComplete);
  };

  const applyCoupon = () => {
    axios
      .post(
        `${API_BASE_URL}coupons/coupon-by-code`,
        { code: inputCouponValue },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserCoupon(res.data);
        }
      })
      .catch((err) => {
        console.log("Error in applyCoupon: ", err);
      });
  };

  const calculateSubTotal = () => {
    return bookingData.bookingAmount;;
  };

  const calculateTotal = () => {
    return calculateSubTotal();
  };

  const handleBooking = () => {
    const formData = {
      hotelId: bookingData.hotelId,
      orderDate: new Date(),
      userId: user?.id || null,
      checkIn: new Date(bookingData.orderDate.checkIn),
      checkOut: new Date(bookingData.orderDate.checkOut),
      currencySymbol: bookingData.currencySymbol,
      details: [
        {
          roomId: bookingData.roomId,
          adult: bookingData.adult,
          children: bookingData.children,
          infant: bookingData.infant,
          quantity: bookingData.quantity,
        },
      ],
      amount: bookingData.bookingAmount,
      createdBy: user?.id || null,
      user: {
        name: `${formModal.firstname} ${formModal.lastname}`,
        companyName: formModal.companyName,
        countryRegion: formModal.countryRegion,
        streetAddress: formModal.streetAddress,
        cityOrTown: formModal.cityOrTown,
        state: formModal.state,
        zipCode: formModal.zipCode,
        phoneNumber: formModal.phoneNumber,
        email: formModal.email,
        password: "password1",
        role: "user",
      },
    };

    axios
      .post(`${API_BASE_URL}landing/booking`, formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.status === 200) {
          setApiResponse(res.data);
          resetCart();
          toast.success("Room has been reserved!");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      })
      .catch((err) => {
        if (isFirstAttempt) {
          setShowConfirmationModal(true);
          setIsFirstAttempt(false);
        } else {
          setApiResponse({ type: "danger", message: "Error placing order" });
        }
      });
  };

  const onSubmit = (e) => {
    // Only prevent default if `e` exists
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (user) {
      handleBooking();
    } else {
      handleBooking();
      setShowConfirmationModal(true);
    }
  };

  return (
    <Container className="place-order-container">
      {apiResponse && Object.keys(apiResponse).length > 0 && (
        <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>
      )}
      <Form validated={validated} noValidate onSubmit={onSubmit}>
        <Card>
          <Card.Header>
            Have a coupon?{" "}
            <Link to={"#"} className="custom-text-color font-14">
              {" "}
              Click here to enter your code
            </Link>
          </Card.Header>
          <Card.Body>
            <FormGroup>
              <FormLabel style={{ fontSize: 12 }}>
                If you have a coupon code, please apply it below.
              </FormLabel>
              <div className="coupon-container">
                <FormControl
                  type="text"
                  value={
                    userCoupon && Object.keys(userCoupon).length > 0
                      ? userCoupon.code
                      : inputCouponValue
                  }
                  disabled={
                    userCoupon && Object.keys(userCoupon).length > 0
                      ? true
                      : false
                  }
                  placeholder="Coupon code"
                  style={{ maxWidth: 400, marginRight: 15 }}
                  onChange={(e) => setInputCouponValue(e.target.value)}
                />
                {userCoupon && Object.keys(userCoupon).length === 0 && (
                  <Button
                    variant="danger"
                    className="bg-custom-variant"
                    onClick={() => applyCoupon()}
                  >
                    Apply Coupon
                  </Button>
                )}
              </div>
            </FormGroup>
          </Card.Body>
        </Card>

        <Row className="mt-3">
          <Col xs={12} md={8}>
            <Card>
              <Card.Header>Billing Details</Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">First name</FormLabel>
                      <FormControl
                        type="text"
                        required
                        value={formModal.firstname}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            firstname: e.target.value,
                          });
                          checkFormCompletion(); // Check completion
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">Last name</FormLabel>
                      <FormControl
                        type="text"
                        required
                        value={formModal.lastname}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            lastname: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">
                        Company Name (Optional)
                      </FormLabel>
                      <FormControl
                        type="text"
                        value={formModal.companyName}
                        onChange={(e) =>
                          setFormModal({
                            ...formModal,
                            companyName: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">
                        Country / Region
                      </FormLabel>
                      <FormControl
                        type="text"
                        value={formModal.countryRegion}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            countryRegion: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">
                        Street Address
                      </FormLabel>
                      <FormControl
                        type="text"
                        required
                        value={formModal.streetAddress}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            streetAddress: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">Town / City</FormLabel>
                      <FormControl
                        type="text"
                        required
                        value={formModal.cityOrTown}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            cityOrTown: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">State</FormLabel>
                      <FormControl
                        type="text"
                        required
                        value={formModal.state}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            state: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">Zip Code</FormLabel>
                      <FormControl
                        type="number"
                        required
                        value={formModal.zipCode}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            zipCode: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">Phone</FormLabel>
                      <FormControl
                        type="text"
                        required
                        value={formModal.phoneNumber}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            phoneNumber: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel className="fontSize-12">Email</FormLabel>
                      <FormControl
                        type="email"
                        required
                        value={formModal.email}
                        onChange={(e) => {
                          setFormModal({
                            ...formModal,
                            email: e.target.value,
                          });
                          checkFormCompletion();
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mt-3">
              <Card.Header>Payment Methods</Card.Header>
              <Card.Body>
                {/* <FormCheck type="radio" value={formModal.paymentMethod} name="methodType" onChange={(e) => setFormModal({ ...formModal, paymentMethod: e.target.value })} label={<RenderRadioButtonText linkTitle={""} linkURL={"#"} heading={"Direct Bank Transfer"} description={"Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account."} />} />
                                <FormCheck type="radio" value={formModal.paymentMethod} name="methodType" onChange={(e) => setFormModal({ ...formModal, paymentMethod: e.target.value })} label={<RenderRadioButtonText linkTitle={""} linkURL={"#"} heading={"Cash on delivery"} description={"Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy."} />} /> */}

                <FormCheck
                  type="radio"
                  checked={formModal.paymentMethod === "DirectBankTransfer"}
                  name="methodType"
                  onChange={() =>
                    setFormModal({
                      ...formModal,
                      paymentMethod: "DirectBankTransfer",
                    })
                  }
                  label={
                    <RenderRadioButtonText
                      linkTitle={""}
                      linkURL={"#"}
                      heading={"Direct Bank Transfer"}
                      description={
                        "Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account."
                      }
                    />
                  }
                />
                <FormCheck
                  type="radio"
                  checked={formModal.paymentMethod === "CashOnDelivery"}
                  name="methodType"
                  onChange={() =>
                    setFormModal({
                      ...formModal,
                      paymentMethod: "CashOnDelivery",
                    })
                  }
                  label={
                    <RenderRadioButtonText
                      linkTitle={""}
                      linkURL={"#"}
                      heading={"Cash on delivery"}
                      description={
                        "Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy."
                      }
                    />
                  }
                />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {isFormComplete
                        ? "Ready to place order"
                        : "Please fill out all required fields"}
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      variant="danger"
                      className="bg-custom-variant mt-3"
                      type="submit"
                      disabled={!isFormComplete}
                      style={!isFormComplete ? { pointerEvents: "none" } : {}}
                      onClick={onSubmit} // Call only onSubmit here
                    >
                      Place Order
                    </Button>
                  </span>
                </OverlayTrigger>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="your-order-card">
              <Card.Header>Your Reservation</Card.Header>
              <Card.Body>
                <div className="d-flex justify-content-space-between">
                  {/* <strong>Product</strong> */}
                  {/* <strong>Subtotal</strong> */}
                </div>
                {bookingData &&
                  bookingData.length > 0 &&
                  bookingData.map((item, key) => (
                    <div
                      className="d-flex justify-content-space-between"
                      key={key}
                    >
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <strong>
                          {item.roomDetail && item.roomDetail.title} x{" "}
                          {item.quantity}
                        </strong>
                        <p className="mb-0">
                          <strong>Start: </strong>
                          <span className="text-muted">
                            {item.orderDate &&
                              format(
                                parse(
                                  item.orderDate.checkIn,
                                  "yyyy/MM/dd",
                                  new Date()
                                ),
                                "MMMM dd, yyyy"
                              )}
                          </span>
                        </p>
                        <p>
                          <strong>End: </strong>
                          <span className="text-muted">
                            {item.orderDate &&
                              format(
                                parse(
                                  item.orderDate.checkOut,
                                  "yyyy/MM/dd",
                                  new Date()
                                ),
                                "MMMM dd, yyyy"
                              )}
                          </span>
                        </p>
                      </div>
                      <span className="text-muted">{`${currencySymbol}${calculateSubTotal(
                        item
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}</span>
                    </div>
                  ))}

                <div className="d-flex justify-content-space-between">
                  <strong>Total</strong>
                  <span>{`${currencySymbol}${(userCoupon &&
                  Object.keys(userCoupon).length > 0
                    ? calculateTotal() -
                      (userCoupon.type === "number"
                        ? userCoupon.amount
                        : (calculateTotal() / 100) * userCoupon.amount)
                    : calculateTotal()
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
      <ToastContainer position="top-right" autoClose={3000} />
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Your Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your account has been set up as a guest. Please confirm to place
            your booking.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowConfirmationModal(false);
              setTimeout(() => {
                handleBooking();
              }, 2000);
            }}
          >
            Place Order
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userCoupon: state.landingPagesInitialState.userCoupon,
  bookingData: state.landingPagesInitialState.bookingData,
});
const dispatchStateToProps = (dispatch) => ({
  setUserCoupon: (obj) => {
    dispatch({
      type: "UPDATE_USER_COUPON",
      payload: obj,
    });
  },
  resetCart: () => {
    dispatch({
      type: "RESET_CART",
      payload: null,
    });
  },
});

export default connect(mapStateToProps, dispatchStateToProps)(PlaceOrder);
