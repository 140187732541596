import { useEffect, useState } from "react";
import { Accordion, FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import RenderStar from "./RenderStar";
import InputRange from 'react18-input-range';
import { API_BASE_URL } from "../../endpoints";

function Filters({
  filters = {},
  setFilters,
  rangeSelectedValue,
  setRangeSelectedValue,
}) {
  const [facilities, setFacilities] = useState([]);
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [roomTypeOptions, setRoomTypes] = useState([]);
  const [expandedItems, setExpandedItems] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
  ]);

  const toggleAccordion = (eventKey) => {
    setExpandedItems((prev) =>
      prev.includes(eventKey)
        ? prev.filter((item) => item !== eventKey)
        : [...prev, eventKey]
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const facilitiesRes = await axios.get(
          `${API_BASE_URL}landing/all-facilities`
        );
        const uniqueFacilities = Array.from(new Set(facilitiesRes.data));
        console.log("Facilities:", uniqueFacilities);
        setFacilities(uniqueFacilities);

        const citiesRes = await axios.get(`${API_BASE_URL}landing/all-cities`);
        const uniqueCities = Array.from(new Set(citiesRes.data));
        setCities(uniqueCities);

        const roomTypesRes = await axios.get(
          `${API_BASE_URL}landing/all-room-types`
        );
        const uniqueRoomTypes = Array.from(new Set(roomTypesRes.data));
        setRoomTypes(uniqueRoomTypes);
        console.log("Room Types:", uniqueRoomTypes);

        const statusesRes = await axios.get(
          ` ${API_BASE_URL}landing/all-statuses`
        );
        const uniqueStatuses = Array.from(new Set(statusesRes.data));
        setStatuses(uniqueStatuses);
        console.log("Statuses:", uniqueStatuses);
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };
    fetchData();
  }, []);

  const applyPriceRangeFilter = (range) => {
    setRangeSelectedValue(range);
    setFilters({
      type: "priceRange",
      value: { min: range.min, max: range.max },
    });
  };

  return (
    <Accordion activeKey={expandedItems} flush className="filter-accordion">
      {/* Star Rating Filter */}
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => toggleAccordion("0")}>
          Star Rating
        </Accordion.Header>
        <Accordion.Body>
          {[5, 4, 3, 2, 1].map((star) => (
            <p className="mb-0" key={star}>
              <FormCheck
                checked={filters?.stars?.includes(String(star)) || false}
                value={String(star)}
                onChange={(e) =>
                  setFilters({ type: "stars", value: e.target.value })
                }
                label={
                  <RenderStar
                    count={star}
                    activeColor="rgba(176, 209, 43, 1)"
                    disableColor="#e5e5e5"
                  />
                }
              />
            </p>
          ))}
        </Accordion.Body>
      </Accordion.Item>

      {/* Guest Rating Filter */}
      <Accordion.Item eventKey="1">
        <Accordion.Header onClick={() => toggleAccordion("1")}>
          Guest Rating
        </Accordion.Header>
        <Accordion.Body>
          {[5, 4].map((guestRating) => (
            <p className="mb-0" key={guestRating}>
              <FormCheck
                value={guestRating}
                onChange={(e) =>
                  setFilters({ type: "guests", value: e.target.value })
                }
                label={
                  <RenderStar
                    count={guestRating}
                    activeColor="rgba(176, 209, 43, 1)"
                    disableColor="#e5e5e5"
                  />
                }
              />
            </p>
          ))}
        </Accordion.Body>
      </Accordion.Item>

      {/* Price Range Filter */}
      {/* Price Range Filter */}
      <Accordion.Item eventKey="2">
  <Accordion.Header onClick={() => toggleAccordion("2")}>
    Price Range ($)
  </Accordion.Header>
  <Accordion.Body>
    <div className="price-range-container">
      <div className="price-range-values">
        <span>${rangeSelectedValue.min}</span>
        <span>${rangeSelectedValue.max}</span>
      </div>
      <div className="price-range-slider">
        <InputRange
          maxValue={9990}
          minValue={0}
          value={rangeSelectedValue}
          onChange={applyPriceRangeFilter}
          formatLabel={() => ""} // Hides labels above handlers
        />
      </div>
    </div>
  </Accordion.Body>
</Accordion.Item>

      <Accordion.Item eventKey="4">
  <Accordion.Header onClick={() => toggleAccordion("4")}>
    Facilities
  </Accordion.Header>
  <Accordion.Body>
    {facilities.map((facility, key) => (
      <p key={key}>
        <FormCheck
          checked={filters?.facilities?.includes(facility.id) || false}
          value={facility.id}
          onChange={(e) =>
            setFilters({ type: "facilities", value: e.target.value })
          }
          label={facility.title}
          className="text-muted"
        />
      </p>
    ))}
    {facilities.length > 6 && (
      <Link to="#" className="custom-text-color">
        Show all {facilities.length}
      </Link>
    )}
  </Accordion.Body>
</Accordion.Item>


      {/* City Filter */}
      <Accordion.Item eventKey="6">
        <Accordion.Header onClick={() => toggleAccordion("6")}>
          City
        </Accordion.Header>
        <Accordion.Body>
          {cities.slice(0, 6).map((city, key) => (
            <p key={key}>
              <FormCheck
                checked={filters?.cities?.includes(city) || false}
                onChange={(e) =>
                  setFilters({ type: "cities", value: e.target.value })
                }
                value={city}
                label={city}
                className="text-muted"
              />
            </p>
          ))}
          {cities.length > 6 && (
            <Link to="#" className="custom-text-color">
              Show all {cities.length}
            </Link>
          )}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header onClick={() => toggleAccordion("7")}>
          Status
        </Accordion.Header>
        <Accordion.Body>
          {statuses.slice(0, 6).map((status, key) => (
            <p key={key}>
              <FormCheck
                checked={filters?.status?.includes(status) || false}
                onChange={(e) =>
                  setFilters({ type: "status", value: e.target.value })
                }
                value={status}
                label={status}
                className="text-muted"
              />
            </p>
          ))}
          {statuses.length > 6 && (
            <Link to="#" className="custom-text-color">
              Show all {statuses.length}
            </Link>
          )}
        </Accordion.Body>
      </Accordion.Item>

      {/* Room Type Filter */}
      <Accordion.Item eventKey="8">
        <Accordion.Header onClick={() => toggleAccordion("8")}>
          Room Type
        </Accordion.Header>
        <Accordion.Body>
          {roomTypeOptions.slice(0, 6).map((type, key) => (
            <p key={key}>
              <FormCheck
                checked={filters?.roomType?.includes(type) || false}
                onChange={(e) =>
                  setFilters({ type: "roomType", value: e.target.value })
                }
                value={type}
                label={type}
                className="text-muted"
              />
            </p>
          ))}
          {roomTypeOptions.length > 6 && (
            <Link to="#" className="custom-text-color">
              Show all {roomTypeOptions.length}
            </Link>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

const mapStateToProps = (state) => {
  console.log(
    "Filters received in AllHotels from Redux:",
    state.landingPagesInitialState.filters
  );
  return {
    filters: state.landingPagesInitialState.filters || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFilters: (obj) => {
    console.log("Dispatching filter update:", obj); // Log dispatched filter object
    dispatch({ type: "UPDATE_FILTERS", payload: obj });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
