import {
  Button,
  Col,
  Dropdown,
  FormCheck,
  FormControl,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { BiSolidSortAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import Add from "./add";
import {
  API_REQUEST_HEADER,
  DELETE_HOTEL_ENDPOINT,
  GET_HOTELS_ENDPOINT,
  GET_HOTEL_DETAILS_ENDPOINT
} from "../../../endpoints"; // Ensure you have the update endpoint defined
import MyPagination from "../rooms/room-pagination";
import axios from "axios";
import {
  capitalizeFirstLetter,
  cutomDateTimeFormat,
  getFormattedDate,
} from "../../../helpers/common-helper";
import Loading from "../../loading";
import SpecialRequestModal from "./notes";
import { apiDeleteWithAuth, apiGetWithAuth } from "../../../hooks/authService";
import { toast } from "react-toastify";

export default function Hotels() {
  const [loading, setLoading] = useState(true);
  let userId = JSON.parse(localStorage.getItem("user"))?.id;
  const [tabActiveKey, setTabActiveKey] = useState("allHotels");
  const [showAddModal, setAddShowModal] = useState(false);
  const [showSpecialRequestModal, setShowSpecialRequestModal] = useState(false);
  const [hotelList, setHotelList] = useState({});
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterStatus, setFilterStatus] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const handleCloseModal = () => {
    setAddShowModal(false);
    setShowSpecialRequestModal(false);
  };
  const statusColors = {
    refund: "text-danger",
    canceled: "text-muted",
    pending: "text-warning",
    booked: "text-success",
  };

  useEffect(() => {
    fetchApiData(userId, pageLimit, selectedPageNumber);
  }, [selectedPageNumber, userId, pageLimit, showAddModal]);

  const fetchApiData = (userId, limit, page) => {
    setLoading(true);
    apiGetWithAuth(GET_HOTELS_ENDPOINT(userId, limit, page))
      .then((data) => {
        setHotelList(data);
      })
      .catch((err) => {
        console.error("Error fetching hotels data:", err);
        alert("Error fetching hotels data. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    fetchApiData(userId, pageLimit, pageNumber);
    setSelectedPageNumber(pageNumber);
  };

  const deleteHotel = (hotelId) => {
    apiDeleteWithAuth(`${DELETE_HOTEL_ENDPOINT}/${hotelId}`)
      .then((response) => {
        if (response.status === 204) {
          fetchApiData(userId, pageLimit, selectedPageNumber);
          toast.success("Hotel deleted successfully!"); // Show success toast
        }
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message || "Failed to delete hotel";
        console.error(errorMessage);
        toast.error(errorMessage); // Show error toast
      });
  };

  const handleViewNoteClick = (item) => {
    setSelectedItem(item);
    setShowSpecialRequestModal(true);
  };

  const handleEditHotel = async (hotelId) => {
    console.log("Edit function triggered with hotel ID:", hotelId);
    try {
        const response = await apiGetWithAuth(GET_HOTEL_DETAILS_ENDPOINT(hotelId));
        console.log("Fetched hotel data:", response); // Verify the entire hotel data object is fetched

        // Check if response is defined and use it directly
        if (response) {
            setSelectedItem(response); // Set the full hotel data for the modal
            setAddShowModal(true); // Open the modal
        } else {
            console.log("No data found for the hotel ID:", hotelId);
        }
    } catch (error) {
        console.error("Error fetching hotel details:", error?.response?.data || error.message);
        alert(`Error loading hotel details: ${error?.response?.data?.message || "Unknown error"}`);
    }
};

  // Function to open Add Hotel modal
const handleAddHotel = () => {
    setSelectedItem(null);  // Clear any selected hotel data
    setAddShowModal(true);  // Open the Add Hotel modal
};

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <ul className="guest-tabs">
                <li className={tabActiveKey === "allHotels" ? "active" : ""}>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setTabActiveKey("allHotels");
                      setFilterStatus("all");
                    }}
                  >
                    All Hotels ({hotelList?.totalResults})
                  </Link>
                </li>
                <li className={tabActiveKey === "pending" ? "active" : ""}>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setTabActiveKey("pending");
                      setFilterStatus("pending");
                    }}
                  >
                    Pending ({hotelList?.pendingHotelsCount})
                  </Link>
                </li>
                <li className={tabActiveKey === "booked" ? "active" : ""}>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setTabActiveKey("booked");
                      setFilterStatus("booked");
                    }}
                  >
                    Booked ({hotelList?.bookedHotelsCount})
                  </Link>
                </li>
                <li className={tabActiveKey === "canceled" ? "active" : ""}>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setTabActiveKey("canceled");
                      setFilterStatus("canceled");
                    }}
                  >
                    Canceled ({hotelList?.canceledHotelsCount})
                  </Link>
                </li>
                <li className={tabActiveKey === "refund" ? "active" : ""}>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setTabActiveKey("refund");
                      setFilterStatus("refund");
                    }}
                  >
                    Refund ({hotelList?.refundHotelsCount})
                  </Link>
                </li>
              </ul>
            </Col>
            <Col
              xs={12}
              md={4}
              className="custom-column-style"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <div className="add-room--search-container">
                <div className="add-room--search-inner-container">
                  <FormControl
                    type="text"
                    placeholder="Search here"
                    className="make-beautify me-2 input-lg"
                    aria-label="Search"
                    style={{
                      minWidth: 350,
                      paddingRight: 30,
                      caretColor: "#008181",
                    }}
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                  />
                  <div style={{ position: "relative", right: 35, top: 0 }}>
                    <Image
                      src="./../images/icons/search.png"
                      width={14}
                      height={14}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              md={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="danger"
                className="btn-custom-color add-new-room-btn fontSize-14 btn-lg"
                onClick={handleAddHotel}
                style={{ alignSelf: "center" }}
              >
                <AiOutlinePlus /> Add Hotel
              </Button>
            </Col>
          </Row>

          <div className="guest-tabs-contents">
            <Table
              striped
              responsive
              hover
              style={{ backgroundColor: "white", marginBottom: 80 }}
              border={"0px #e5e5e5"}
            >
              <thead>
                <tr>
                  <th>
                    <FormCheck />
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Name{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Arrival Date & Time{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Pickup{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Departure{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Special Request{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Room Type{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      Status{" "}
                      <BiSolidSortAlt
                        color="#D3D6E4"
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {hotelList && hotelList?.hotels.length > 0 ? (
                  hotelList.hotels.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <FormCheck type="checkbox" />
                      </td>
                      <td>{capitalizeFirstLetter(item?.name)}</td>
                      <td>
                        {item?.arrivalDateTime
                          ? cutomDateTimeFormat(
                              "date",
                              item?.arrivalDateTime.date
                            ) +
                            " " +
                            cutomDateTimeFormat(
                              "time",
                              item?.arrivalDateTime.time
                            )
                          : "-"}
                      </td>
                      <td>{item?.pickup || "-"}</td>
                      <td>
                        {item?.departureDate
                          ? cutomDateTimeFormat(
                              "date",
                              item?.departureDate.date
                            ) +
                            " " +
                            cutomDateTimeFormat(
                              "time",
                              item?.departureDate.time
                            )
                          : "-"}
                      </td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleViewNoteClick(item)}
                        >
                          View
                        </Button>
                      </td>
                      <td>{item?.roomType || "-"}</td>
                      <td>
                        <span className={statusColors[item?.status]}>
                          {item?.status || "-"}
                        </span>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Dropdown>
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <BsThreeDots color="#555" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleEditHotel(item.id)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => deleteHotel(item.id)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}>
                      No Hotels Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <MyPagination
              currentPage={selectedPageNumber}
              pageCount={Math.ceil(hotelList?.totalResults / pageLimit)}
              onPageChange={handlePageChange}
              onLimitChange={setPageLimit}
            />
          </div>
          
        </>
      )}
      {showAddModal && (
        <Add
          show={showAddModal}
          onHide={handleCloseModal}
          hotelData={selectedItem} // Pass the selected hotel for editing
          userId={userId}
          fetchHotels={() =>
            fetchApiData(userId, pageLimit, selectedPageNumber)
          } // Callback to refresh hotel list after adding or updating
        />
      )}
      {showSpecialRequestModal && (
        <SpecialRequestModal
          show={showSpecialRequestModal}
          onHide={handleCloseModal}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
}
