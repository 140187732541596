export function getFormattedDate(inputDateString, format) {
  console.log("Input date string:", inputDateString); // Check the input value
  const inputDate = new Date(inputDateString);

  if (isNaN(inputDate.getTime())) {
    console.error("Invalid date provided:", inputDateString);
    return "Invalid date";
  }

  if (format === 'monthDay') {
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(inputDate);
    const dayWithSuffix = getDayWithSuffix(inputDate.getDate());
    return `${monthName}, ${dayWithSuffix}`;
  } else if (format === 'onlyDay') {
    return getDayWithSuffix(inputDate.getDate());
  }
}

export function formatDateString(dateString) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return "Invalid Date";  // Return a default value if the date is invalid
  }

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  
  return `${month} ${day}/${year}`;
}

function getDayWithSuffix(day) {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }

  const lastDigit = day % 10;

  switch (lastDigit) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export function capitalizeFirstLetter(str) {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export function cutomDateTimeFormat(type, value, isMonthShort = false, showDay = true) {
  const date = new Date(value);
  
  if (isNaN(date.getTime())) {
    console.error("Invalid date provided:", value);
    return "Invalid Date";
  }

  if (type === 'date') {
    const options = showDay 
      ? { weekday: 'long', year: 'numeric', month: isMonthShort ? 'short' : 'long', day: 'numeric' }
      : { year: 'numeric', month: isMonthShort ? 'short' : 'long', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  } else if (type === 'time') {
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

    return `${formattedHours}:${minutes} ${period}`;
  }
}

export const formatReviewDateTime = (dateTimeString) => {
  console.log("DateTime string:", dateTimeString);  // Log the input string
  const date = new Date(dateTimeString);
  console.log("Parsed Date object:", date);  // Log the parsed Date object

  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateTimeString);
    return "Invalid Date";
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
};

export const generateRandomPassword = (length) => {
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";

  // Ensure at least one letter and one number
  const randomLetter = letters[Math.floor(Math.random() * letters.length)];
  const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];

  // Generate the remaining characters
  const charset = letters + numbers;
  let password = randomLetter + randomNumber;

  for (let i = password.length; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
};

export const formateOrderDateTime = (type, dateString) => {
  const dateObject = new Date(dateString);

  if (type === 'date') {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    return dateObject.toLocaleDateString('en-US', options);
  } else if (type === 'time') {
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour format with AM/PM
      hourCycle: 'h23', // Use 24-hour format for consistent hour display
    };

    return dateObject.toLocaleTimeString('en-US', options);
  } else {
    // Handle other types if needed
    return 'Invalid type';
  }
};

export const getDayNamesAndTimes = (startDate, endDate, daysOnly) => {
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  const dayNamesAndTimes = [];

  let currentDate = startDateTime;

  while (currentDate <= endDateTime) {
    const dayName = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(currentDate);
    const startTime = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day

    const endTime = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    dayNamesAndTimes.push({
      dayName,
      startTime,
      endTime,
    });
  }

  let days = '';
  let times;
  if (dayNamesAndTimes && dayNamesAndTimes.length > 0) {
    days = dayNamesAndTimes.map((val, key) => {
      if (key === dayNamesAndTimes.length - 1) {
        return val.dayName;
      } else {
        return val.dayName + ', ';
      }
    });
    if (dayNamesAndTimes && dayNamesAndTimes.length > 0) {
      times = dayNamesAndTimes.map((val, key) => {
        if (key === dayNamesAndTimes.length - 1) {
          return val.startTime + '-' + val.endTime;
        } else {
          return '';  // or return some default value if the condition is not satisfied
        }
      });
    }
  }

  if (daysOnly) {
    return days;
  }

  return times;
}

// Helper function to get the initial state for formModal
export const getRoomInitalState = () => ({
  title: '',
  userId: JSON.parse(localStorage.getItem("user"))?.id,
  thumbnails: [],
  roomNumber: '',
  bedType: 'single',
  floor: '',
  status: 'available',
  facilities: [],
  prices: [
    getDefaultSeasonData("Season 1"),
    getDefaultSeasonData("Season 2")
  ],
  hotelId: '',
  capacity: {
    adult: 1,
    children: 0,
    infant: 0,
    overall: '1 Person',
  }
});

// Helper function to get default season data
const getDefaultSeasonData = (seasonName) => ({
  seasonName,
  dateRange: {
    from: new Date(),
    to: new Date()
  },
  price: seasonName === "Season 1" ? 1000 : 2000,
  currencySymbol: "USD"
});
