import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
//import { offers } from "../../appData";
//import SearchResultSingleItem from "./SearchResultSingleItem";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const OfferSlider = ({ offers }) => {
    const [itemsPerSlide, setItemsPerSlide] = useState(2);
    let numSlides = Math.ceil(offers.length / itemsPerSlide);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 992) {
                setItemsPerSlide(2);
            } else {
                setItemsPerSlide(1);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {/* <h4>Offers</h4> */}
            <Row className="mb-3">
                <Carousel indicators={false}>
                    {Array.from({ length: numSlides }).map((_, slideIndex) => (
                        <Carousel.Item key={slideIndex}>
                            <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
                                <Row>
                                    {offers.slice(
                                        slideIndex * itemsPerSlide,
                                        (slideIndex + 1) * itemsPerSlide
                                    ).map((offer, itemIndex) => (
                                        <Col key={itemIndex} xs={12} md={6}>
                                            {offer.displayTemplate === 'horizontal' ?
                                                <Card className='no-border' style={{ height: 160 }}>
                                                    <Row>
                                                        <Col md={8} xs={12}>
                                                            <Card.Body style={{paddingTop: 0}}>
                                                                <div className='d-flex justify-content-center' style={{ flexDirection: "column", height: 160 }}>
                                                                    <h5>{offer.title}</h5>
                                                                    <p className="font-12">{offer.description}</p>
                                                                    <Button variant="primary" className="btn-find-a-stay">Find a stay</Button>
                                                                </div>
                                                            </Card.Body>
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <Card.Img variant="top" src={offer.thumbnail} style={{ height: 160 }} />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                :
                                                <Card className='remove-border-radius'>
                                                    <Card.Img variant="top" src={offer.thumbnail} style={{ height: 160 }} />
                                                    <Card.ImgOverlay style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: 160 }}>
                                                        <div className="d-flex justify-content-center text-white col-md-8" style={{ flexDirection: "column", height: 160 }}>
                                                            <h5>{offer.title}</h5>
                                                            <p className="font-12">{offer.description}</p>
                                                            <Button variant="primary" className="btn-find-a-stay">Find a stay</Button>
                                                        </div>
                                                    </Card.ImgOverlay>
                                                </Card>
                                            }
                                            {/* <SearchResultSingleItem imgUrl={offer.thumbnail} requestFrom='offers' item={offer} /> */}
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
        </>
    );
}

const mapStateToProps = (state) => ({
    offers: state.landingPagesInitialState.offers
});

export default connect(mapStateToProps, {})(OfferSlider);