const initialState = {
    dashboardInitialState: {
        defaultActiveSidebarItem: 'dashboard',
        bookingSearchResults: [],
        bookingSearchItemSelectedId: '',
        bookingSearhFormModal: {}
    },
    landingPagesInitialState: {
        defaultActiveNavbar: localStorage.getItem('defaultActiveNavbar') || 'home',
        offers: [],
        topDestinations: [],
        trending: [],
        deals: [],
        recommended_hotels: [],
        popular_activities: [],
        trending_rental: [],
        cart: [],
        filters: {
            stars: [],
            guests: [],
            prices: {},
            meals: [],
            facilities: [],
            propertyTypes: [],
            cities: [],
            status: [],
            roomType: [],
        },
        userCoupon: {},
        searchResults: {}
    },
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_DASHBOARD_SIDEBAR_DEFAULT_ITEM":
            return {
                ...state,
                dashboardInitialState: {
                    defaultActiveSidebarItem: action.payload
                }
            };
        case "UPDATE_LANDING_PAGES_ACTIVE_NAVBAR":
            localStorage.setItem('defaultActiveNavbar', action.payload);
            return {
                ...state,
                landingPagesInitialState: {
                    defaultActiveNavbar: action.payload
                }
            };
        case "UPDATE_LANDING_PAGES_INITIAL_STATE":
            return {
                ...state,
                landingPagesInitialState: action.payload
            }
        case "UPDATE_TOP_DESTINATIONS":
            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    topDestinations: action.payload
                }
            }
        case "UPDATE_TRENDING":
            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    trending: action.payload
                }
            }
        case "UPDATE_CART":
            if (typeof action.payload === "object" && action.payload !== null) {
                const currentCart = state.landingPagesInitialState.cart || []; // Ensure cart is initialized as an array

                return {
                    ...state,
                    landingPagesInitialState: {
                        ...state.landingPagesInitialState,
                        cart: [...currentCart, action.payload],
                    },
                };
            } else {
                return state;
            }
        case "REMOVE_ITEM_FROM_CART":
            const updatedCart = state.landingPagesInitialState.cart.filter(
                (item, key) => key !== action.payload
            );

            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    cart: updatedCart,
                },
            };
        case "UPDATE_CART_QUANTITY":
            const { key, quantity } = action.payload;

            const itemIndex = state.landingPagesInitialState.cart.findIndex((item, index) => index === key);
            if (itemIndex !== -1) {
                const updatedCart = [...state.landingPagesInitialState.cart];
                updatedCart[itemIndex] = {
                    ...updatedCart[itemIndex],
                    quantity: quantity
                };

                return {
                    ...state,
                    landingPagesInitialState: {
                        ...state.landingPagesInitialState,
                        cart: updatedCart
                    }
                };
            } else {
                return state;
            }
            case "UPDATE_FILTERS":
                const value = action.payload.value;
                const type = action.payload.type;
            
                const landingPagesInitialState = state.landingPagesInitialState || {};
                const filters = landingPagesInitialState.filters || {};
            
                let updatedFilters;
                switch (type) {
                    case "stars":
                    case "guests":
                    case "meals":
                    case "facilities":
                    case "propertyTypes":
                    case "cities":
                    case "status":  // Add status to the filter types
                    case "roomType":  // Add roomType to the filter types
                        const currentFilter = filters[type] || [];
                        const updatedFilter = currentFilter.includes(value)
                            ? currentFilter.filter((item) => item !== value)  // Remove if already selected
                            : [...currentFilter, value];  // Add if not already selected
            
                        updatedFilters = {
                            ...filters,
                            [type]: updatedFilter,
                        };
                        break;
            
                    default:
                        updatedFilters = filters;
                        break;
                }
            
                return {
                    ...state,
                    landingPagesInitialState: {
                        ...landingPagesInitialState,
                        filters: updatedFilters,
                    },
                };            
        case "RESET_FILTERS":
            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    filters: {
                        stars: [],
                        guests: [],
                        prices: {},
                        meals: [],
                        facilities: [],
                        propertyTypes: [],
                        cities: [],
                        status: [],  // Reset status filter
                        roomType: [],
                    },
                },
            };
        case "UPDATE_DASHBOARD_BOOKING_SEARCH_RESULTS":
            return {
                ...state,
                dashboardInitialState: {
                    ...state.dashboardInitialState,
                    bookingSearchResults: action.payload
                }
            };
        case "UPDATE_BOOKING_SEARCH_ITEM_SELECTED_ID":
            return {
                ...state,
                dashboardInitialState: {
                    ...state.dashboardInitialState,
                    bookingSearchItemSelectedId: action.payload
                }
            }
        case "UPDATE_BOOKING_SEARCH_FORM_MODAL":
            return {
                ...state,
                dashboardInitialState: {
                    ...state.dashboardInitialState,
                    bookingSearhFormModal: action.payload
                }
            }
        case "UPDATE_USER_COUPON":
            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    userCoupon: action.payload
                }
            }
        case "UPDATE_LANDING_SEARCH_RESULTS":
            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    searchResults: action.payload
                }
            }
        case "RESET_CART":
            return {
                ...state,
                landingPagesInitialState: {
                    ...state.landingPagesInitialState,
                    cart: []
                }
            }
        default:
            return state;
    }
}

export default rootReducer;
