import { faBuilding, faCalendar, faHouse, faLocation, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Form, FormControl, FormGroup, FormLabel, Image, Row } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { formatDateString } from '../../helpers/common-helper';
import axios from 'axios';
import { API_BASE_URL } from '../../endpoints';
import { connect } from 'react-redux';


const HomePageSearch = ({ textColor, bg, requestFrom, setSearchResults }) => {
    const [key, setKey] = useState('hotel');
    const [validated, setValidated] = useState(false);
    const [isDateRangePickerEvent, setIsDateRangePickerEvent] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [formModal, setFormModal] = useState({
        destination: '',
        dateRange: {
            start: 'Aug 26/2024',
            end: 'Aug 27/2024',
            formatedStart: new Date('YYYY-MM-DD'),
            formatedEnd: new Date('YYYY-MM-DD')
        },
        rooms: 1,
        adult: 1,
        children: 0,
        infant: 0,
        limit: 18,
        page: 1,
        sortBy: 'ASC'
    });

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isDateRangePickerEvent) {
            return;
        }

        if (!formModal.destination) {
            setValidated(true);
            return;
        }

        axios.post(`${API_BASE_URL}landing/search`, formModal, {
            headers: {
                'accept': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                const resultsWithConsistentFields = response.data.results.map((hotel) => ({
                    ...hotel,
                    _id: hotel.id || hotel._id, // Ensure `_id` is defined
                    thumbnail: hotel.thumbnails?.[0] || "" // Ensure a `thumbnail` field
                }));
                setSearchResults(resultsWithConsistentFields);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleDateRangeApply = (event, picker) => {
        const startDate = picker.startDate.format('MM/DD/YYYY');
        const endDate = picker.endDate.format('MM/DD/YYYY');

        setFormModal({
            ...formModal,
            dateRange: {
                start: formatDateString(startDate),
                end: formatDateString(endDate),
                formatedStart: startDate,
                formatedEnd: endDate
            },
        });
    }

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const hideDropdown = () => {
        setDropdownOpen(false);
    };

    const tabContent = <div style={{ backgroundColor: bg === 'transparent' ? 'white' : 'rgba(0,0,0,0.2)', padding: '15px' }}>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
        <Row className="bg-light box-white home-search-bar">
  <Col xs={12} md={3} className="mb-2">
    <FormGroup>
      <FormLabel className="text-muted font-12">Destination or Hotel Name</FormLabel>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{
          position: "relative",
          left: 0,
          top: 0,
          background: 'white',
          height: '37px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 10,
          borderBottom: '1px solid #dee2e6'
        }}>
          <FontAwesomeIcon icon={faLocation} color="#008181" />
        </div>
        <FormControl
          type="text"
          placeholder="Where are you going?"
          className="material-input"
          required
          value={formModal.destination}
          onChange={(e) => setFormModal({ ...formModal, destination: e.target.value })}
        />
      </div>
    </FormGroup>
  </Col>
  <Col xs={12} md={4}>
    <FormGroup>
      <FormLabel className="text-muted font-12">Check In - Out</FormLabel>
      <DateRangePicker
        initialSettings={{ startDate: new Date(), endDate: '1/1/2050' }}
        onApply={handleDateRangeApply}
      >
        <button className="form-control material-input" style={{ textAlign: 'left' }}>
          <FontAwesomeIcon icon={faCalendar} color="#008181" />
          <span style={{ marginLeft: 15 }} className="text-muted">
            {`${formModal.dateRange.start} - ${formModal.dateRange.end}`}
          </span>
        </button>
      </DateRangePicker>
    </FormGroup>
  </Col>
  <Col xs={12} md={3} className="mb-3">
    <FormGroup>
      <FormLabel className="text-muted font-12">Rooms and Guests</FormLabel>
      <div className="dropdown">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{
            position: "relative",
            left: 0,
            top: 0,
            background: 'white',
            height: '37px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 10,
            borderBottom: '1px solid #dee2e6'
          }}>
            <FontAwesomeIcon icon={faUsers} color="#008181" />
          </div>
          <input
            type="text"
            placeholder="Adults, Children, Infant"
            value={`Adult ${formModal.adult}, Childs ${formModal.children}, Infant ${formModal.infant}`}
            onClick={toggleDropdown}
            className="form-control material-input"
          />
        </div>
        {isDropdownOpen && (
          <div className="dropdown-content">
            <input
              type="number"
              placeholder="Adult"
              className="form-control mb-2"
              value={formModal.adult}
              onChange={(e) => setFormModal({ ...formModal, adult: e.target.value })}
            />
            <input
              type="number"
              placeholder="Children"
              className="form-control mb-2"
              value={formModal.children}
              onChange={(e) => setFormModal({ ...formModal, children: e.target.value })}
            />
            <input
              type="number"
              placeholder="Infant"
              className="form-control mb-2"
              value={formModal.infant}
              onChange={(e) => setFormModal({ ...formModal, infant: e.target.value })}
            />
            <button className="custom-done-btn" onClick={hideDropdown}>
              Done
            </button>
          </div>
        )}
      </div>
    </FormGroup>
  </Col>
  <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
    <div className="d-grid gap-2" style={{ width: '100%' }}>
      <Button type="submit" variant="danger" size="lg" style={{ backgroundColor: "#008181" }}>
        Search
      </Button>
    </div>
  </Col>
</Row>
        </Form>
    </div>;

    return (
        <div>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="hotel" title={
                    <span style={{ color: key === 'hotel' ? '#008181' : (textColor !== undefined ? textColor : 'white') }}>
                        {requestFrom === 'booking' ? <Image src="./../images/icons/hotel.png" width={21} height={25} /> : <FontAwesomeIcon icon={faBuilding} />}
                        {" Hotel"}
                    </span>
                }>
                    {tabContent}
                </Tab>
                {/* <Tab eventKey="tours" title={
                    <span style={{ color: textColor !== undefined ? textColor : 'white' }}>
                        {requestFrom === 'booking' ? <Image src="./../images/icons/tours.png" width={21} height={21} /> : <FontAwesomeIcon icon={faEarthAfrica} />}
                        {" Tours"}
                    </span>
                }
                >
                    {tabContent}
                </Tab> */}
                 <Tab eventKey="activity" title={
                    <span style={{ color: key === 'activity' ? '#008181' : (textColor !== undefined ? textColor : 'white') }}>
                        {requestFrom === 'booking' && <Image src="./../images/icons/activity.png" width={21} height={21} />}
                        {" Activity"}
                    </span>
                }>
                    {tabContent}
                </Tab>
                {/* <Tab eventKey="rental" title={
                    <span style={{ color: textColor !== undefined ? textColor : 'white' }}>
                        {requestFrom === 'booking' ? <Image src="./../images/icons/rental.png" width={21} height={21} /> : <FontAwesomeIcon icon={faHouse} />}
                        {" Rental"}
                    </span>}>
                    {tabContent}
                </Tab>  */}
            </Tabs>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => ({
    setSearchResults: (results) => {
      dispatch({
        type: "UPDATE_LANDING_SEARCH_RESULTS",
        payload: results
      });
    }
  });
  
  export default connect(null, mapDispatchToProps)(HomePageSearch);