import { Button, Carousel, Col, Dropdown, FormCheck, FormControl, Image, Row, Table } from "react-bootstrap";
import { BiSolidSortAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import Add from "./add";
import axios from "axios";
import { API_REQUEST_HEADER, DELETE_ROOM_ENDPOINT, GET_ROOMS_ENDPOINT } from "../../../endpoints";
import { getFormattedDate } from "../../../helpers/common-helper";
import MyPagination from "./room-pagination";
import Loading from "../../loading";
import { IoIosPerson } from "react-icons/io";
import { FaBaby, FaChild } from "react-icons/fa";
import ConfirmDelete from "../booking/confirm-delete";
import { apiDeleteWithAuth, apiGetWithAuth } from "../../../hooks/authService";

export default function Rooms() {
    const [loading, setLoading] = useState(true);
    const [tabActiveKey, setTabActiveKey] = useState('allRooms');
    const [showAddModal, setAddShowModal] = useState(false);
    const [roomList, setRoomList] = useState({});
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(5);
    const [filterStatus, setFilterStatus] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [roomAdded, setRoomAdded] = useState(false);
    const [roomUpdated, setRoomUpdated] = useState(false);
    const [editModal, setEditModal] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedRoomIDforDelete, setSelectedRoomIDForDelete] = useState(null);

    useEffect(() => {
        fetchApiData(userId, pageLimit, selectedPageNumber);
    }, [selectedPageNumber, userId, pageLimit]);

    useEffect(() => {
        if (!showAddModal && (roomAdded || roomUpdated)) {
            fetchApiData(userId, pageLimit, selectedPageNumber);
        }
    }, [showAddModal, roomAdded, roomUpdated]);

    const fetchApiData = (userId, limit, page) => {
        let accessToken = localStorage.getItem('accessToken');
        setLoading(true);
        apiGetWithAuth(GET_ROOMS_ENDPOINT(userId, limit, page))
            .then(data => {
                setRoomList(data); // Directly set the room list without filtering
            })
            .catch(err => {
                console.error("Error fetching rooms:", err);
                alert("Error fetching rooms data. Please try again later.");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const handlePageChange = (pageNumber) => {
        fetchApiData(userId, pageLimit, pageNumber);
        setSelectedPageNumber(pageNumber);
    };

    const deleteRoom = () => {
        // Construct the endpoint with the selected room ID
        const endpoint = DELETE_ROOM_ENDPOINT(selectedRoomIDforDelete);
    
        apiDeleteWithAuth(endpoint)
            .then((response) => {
                if (response.status === 204) {
                    setShowConfirmModal(false); // Close the confirmation modal
                    fetchApiData(userId, pageLimit, selectedPageNumber); // Refresh the room list
                }
            })
            .catch((err) => {
                console.log(err?.response?.data?.message || "Error deleting room.");
            });
    };
    
    const editRoom = (obj) => {
        setEditModal(obj); // Set the room object to edit
        setAddShowModal(true); // Show the modal for editing
    };
    
    const confirmDeleteRoom = (id) => {
        setSelectedRoomIDForDelete(id); // Set the ID of the room to delete
        setShowConfirmModal(true); // Show the confirmation modal
    };

    return (
        <>
            {loading ? <Loading />
                :
                <>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <ul className="guest-tabs">
                                <li className={tabActiveKey === 'allRooms' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('allRooms'); setFilterStatus('all') }}>All Rooms ({roomList?.totalResults})</Link></li>
                                <li className={tabActiveKey === 'availableRooms' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('availableRooms'); setFilterStatus('available') }}>Available Rooms ({roomList?.availableRoomsCount})</Link></li>
                                <li className={tabActiveKey === 'booked' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('booked'); setFilterStatus('booked') }}>Booked Rooms ({roomList?.bookedRoomsCount})</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={4} className="custom-column-style" style={{ display: "flex", justifyContent: 'flex-end', alignItems: "flex-start" }}>
                            <div className="add-room--search-container">
                                <div className="add-room--search-inner-container">
                                    <FormControl
                                        type="text"
                                        placeholder="Search here"
                                        className="make-beautify me-2 input-lg"
                                        aria-label="Search"
                                        style={{ minWidth: 350, paddingRight: 30, caretColor: '#008181' }}
                                        value={searchText}
                                        onChange={(event) => setSearchText(event.target.value)}
                                    />
                                    <div style={{ position: "relative", right: 35, top: 0 }}>
                                        <Image src='./../images/icons/search.png' width={14} height={14} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button variant="danger" className="btn-custom-color add-new-room-btn btn-lg fontSize-14" onClick={() => { setEditModal({}); setAddShowModal(true) }} style={{ alignSelf: "center" }}><AiOutlinePlus /> Add New Room</Button>
                        </Col>
                    </Row>

                    <div className="guest-tabs-contents">
                        <Table striped responsive hover style={{ backgroundColor: "white" }} border={"0px #e5e5e5"}>
                            <thead>
                                <tr>
                                    <th><FormCheck /></th>
                                    <th><div className="d-flex align-items-center">Room Name <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Bed Type <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Room Floor <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Room Facilities <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Capacity <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Status <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {roomList && roomList.rooms && roomList.rooms.length > 0
                                    ? roomList.rooms
                                        .filter((rm) => {
                                            return (
                                                (filterStatus === 'all' || ((filterStatus === 'available' || filterStatus === 'Available') && rm.status.toLowerCase() !== 'booked') || (filterStatus === 'booked' && rm.status.toLowerCase() === 'booked')) &&
                                                ((rm.title && rm.title.toLowerCase().includes(searchText.toLowerCase())) || rm.roomNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    (rm.facilities && rm.facilities.some(facility => facility.title.toLowerCase().includes(searchText.toLowerCase()))))
                                            );
                                        })
                                        .map((rm, key) => (
                                            <tr key={key}>
                                                <td><FormCheck /></td>
                                                <td style={{ width: 350 }}>
                                                    <div className="d-flex align-items-center">
                                                        <Carousel indicators={false} className="roomSlider" style={{ maxWidth: 200 }}>
                                                            {rm.thumbnails && rm.thumbnails.length > 0 && rm.thumbnails.map((slide, keyy) => (
                                                                <Carousel.Item key={keyy}>
                                                                    <img
                                                                        className="d-block w-100"
                                                                        style={{ height: 100 }}
                                                                        src={slide}
                                                                        alt="Second slide"
                                                                    />
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>

                                                        <div className="d-flex" style={{ flexDirection: "column", marginLeft: 15 }}>
                                                            <Link to={"#"} className="custom-text-color">{rm.roomNumber}</Link>
                                                            <strong>{rm.title}</strong>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ padding: 15, verticalAlign: "middle" }}>
                                                    {rm.bedType}
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>{rm.floor}</td>
                                                <td style={{ maxWidth: 200, verticalAlign: "middle" }}>
                                                    {rm.facilities && rm.facilities.length > 0 && (
                                                        <span className='room-facility-span'>
                                                            {rm.facilities.map((fac, key) => (
                                                                key === 0 ? fac.title : `, ${fac.title}`
                                                            ))}
                                                        </span>
                                                    )}
                                                </td>
                                                <td style={{ verticalAlign: "middle", textAlign: 'left' }}>
                                                    {rm.roomCapacity && rm.roomCapacity.overall ? rm.roomCapacity.overall : '-'}
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <span className={rm?.status.toLowerCase() === 'booked' ? "text-booked" : 'text-custom-success'}>
                                                            {rm.status}
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            {(rm.status === 'booked' || rm.status === 'Booked') &&
                                                                rm.prices && rm.prices.length > 0 && rm.prices.map((obj, key) => (
                                                                    <span>{getFormattedDate(obj.dateRange?.from, 'monthDay') + " - " + getFormattedDate(obj.dateRange?.to, 'onlyDay')}</span>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="more-vertical"><BsThreeDots color="black" /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => editRoom(rm)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => confirmDeleteRoom(rm._id)}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td colSpan={7} className="text-center text-danger">No record found</td></tr>}
                            </tbody>
                        </Table>
                        <MyPagination list={roomList?.rooms} totalPages={roomList?.totalPages} page={roomList?.page} pageLimit={pageLimit} totalResults={roomList?.totalResults} onPageChange={handlePageChange} />
                    </div>

                    <Add
                        show={showAddModal}
                        onHide={() => setAddShowModal(false)}
                        setRoomAdded={setRoomAdded}
                        setRoomUpdated={setRoomUpdated}
                        editModal={editModal}
                        setEditModal={setEditModal}
                    />

                    <ConfirmDelete
                        textBody="Are you sure you want to delete this room?"
                        show={showConfirmModal}
                        onHide={() => setShowConfirmModal(false)}
                        delete={deleteRoom}
                    />
                </>
            }
        </>
    );
}