import { Card, Col, Container, Row } from "react-bootstrap";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Rating from "react-rating";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import MyPagination from "../rooms/room-pagination";

const SearchResults = ({ hotelList, setBookingSearchItemSelectedId }) => {
    const navigate = useNavigate();
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(18);
    const [displayView, setDisplayView] = useState('gird');

    const handlePageChange = (pageNumber) => {
        setSelectedPageNumber(pageNumber);
    };

    useEffect(() => {
        console.log("hotelList", hotelList);
    }, [hotelList]);

    const handleItemClick = (e, id) => {
        e.preventDefault();
        setBookingSearchItemSelectedId(id);
        navigate('/dashboard/search-hotel-detail');
    }

    const toggleView = (event, type) => {
        event.preventDefault();
        setDisplayView(type);
    }
    return (
        <div className="p-2">
            <Row>
                <Col xs={12} md={8}>
                    <h5>Hotels: {hotelList?.totalResults} results found</h5>
                </Col>
                <Col xs={12} md={4}>
                    <div className="d-flex justify-content-end align-items-center">
                        <Link to={"#"} onClick={(e) => toggleView(e, 'list')}><FaList className="me-2" color={displayView === 'gird' ? "#e5e5e5" : "#008181"} /></Link>
                        <Link to={"#"} onClick={(e) => toggleView(e, 'gird')}><BsFillGrid3X3GapFill color={displayView === 'list' ? "#e5e5e5" : "#008181"} /></Link>
                    </div>
                </Col>
            </Row>


            <Row className="mt-3">
                {hotelList && hotelList.results && hotelList.results.length > 0 && hotelList.results.map((item, key) => {
                    return (
                        displayView === 'gird' ?
                            <Col xs={6} md={3} key={key} className="mb-3 p-0 me-2">
                                <Link to={'#'} onClick={(e) => handleItemClick(e, item?.id)} className="text-decoration-none">
                                    <Card>
                                        <Card.Img variant="top" src={item.thumbnails && item.thumbnails.length > 0 && item.thumbnails[0]} style={{ height: 200 }} />
                                        <Card.Body>
                                            <Rating
                                                readonly
                                                initialRating={item?.rating}
                                                emptySymbol={<AiOutlineStar />}
                                                fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                            />
                                            <Card.Title style={{ fontSize: 14, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 3, height: "3.222rem" }}>{item.description}</Card.Title>
                                            <p className="text-muted fontWeight-12 mb-2">
                                                <span className="badge bg-danger">{item.rating ? item?.rating.toFixed(1) : '0.0'}/5</span>
                                                <span> ({item?.reviews?.length} reviews)</span>
                                            </p>
                                            {item.priceFrom && Object.keys(item.priceFrom).length > 0 && <p className="mb-0 text-muted">From <strong> {` ${item.priceFrom.currencySymbol}${item.priceFrom.price}`}</strong></p>}
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            :

                            <Col xs={12} md={6}>
                                <Link to={'#'} onClick={(e) => handleItemClick(e, item?.id)} className="text-decoration-none">
                                    <Card className="no-border-radius mb-2 p-0">
                                        <Card.Body className="p-0 m-0">
                                            <Container className="p-0">
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <Card.Img variant="top" src={item.thumbnails && item.thumbnails.length > 0 && item.thumbnails[0]} style={{ height: 200, borderTopRightRadius: 0, borderTopLeftRadius: 0 }} />
                                                    </Col>
                                                    <Col xs={12} md={8} style={{ verticalAlign: 'middle', display: 'flex' }}>
                                                        <div style={{ paddingRight: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <Rating
                                                                readonly
                                                                initialRating={item?.rating}
                                                                emptySymbol={<AiOutlineStar />}
                                                                fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                                            />
                                                            <Card.Title style={{ fontSize: 14, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 3, height: "3.222rem" }}>{item.description}</Card.Title>
                                                            <p className="text-muted fontWeight-12 mb-2">
                                                                <span className="badge bg-danger">{item.rating ? item?.rating.toFixed(1) : '0.0'}/5</span>
                                                                <span> ({item?.reviews?.length} reviews)</span>
                                                            </p>
                                                            {item.priceFrom && Object.keys(item.priceFrom).length > 0 && <p className="mb-0 text-muted">From <strong> {` ${item.priceFrom.currencySymbol}${item.priceFrom.price}`}</strong></p>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                    );
                })}
            </Row>

            <MyPagination list={hotelList?.hotels} totalPages={hotelList?.totalPages} page={hotelList?.page} pageLimit={pageLimit} totalResults={hotelList?.totalResults} onPageChange={handlePageChange} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    hotelList: state.dashboardInitialState.bookingSearchResults
});

const dispatchStateToProps = (dispatch) => ({
    setBookingSearchItemSelectedId: (id) => {
        dispatch({
            type: "UPDATE_BOOKING_SEARCH_ITEM_SELECTED_ID",
            payload: id
        })
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(SearchResults);