import React, { useEffect, useState, useCallback } from "react";
import { apiGetWithAuth } from "../../hooks/authService";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { reservationStatistic } from "../../appData";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Rating from "react-rating";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { RxCrossCircled } from "react-icons/rx";
import { FaEllipsisV } from "react-icons/fa";
import { API_BASE_URL } from "../../endpoints";
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  AreaChart,
  Area,
} from "recharts";
import {
  formatReviewDateTime,
  formateOrderDateTime,
} from "../../helpers/common-helper";

export default function Dashboard(props) {
  let userId = JSON.parse(localStorage.getItem("user"))?.id;
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);
  // const percentage = 60;
  // Import the helper function

  const getDashboard = useCallback(
    (id) => {
      setLoading(true);

      // Use the apiGetWithAuth function for automatic token refresh
      apiGetWithAuth(`${API_BASE_URL}dashboard?userId=${id}`)
        .then((data) => {
          // Assuming the response is already processed by apiGetWithAuth
          setPageData(data);
          console.log("Page data:", data);
        })
        .catch((err) => {
          console.error("Error fetching dashboard data:", err);
          alert("Error fetching dashboard data. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userId]
  );

  // useEffect to trigger getDashboard when userId changes
  useEffect(() => {
    if (userId) {
      getDashboard(userId);
    }
  }, [userId, getDashboard]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const { newBookings, scheduleRooms, checkIn, checkOut } = pageData.stats || {};
  const { availableRoomsCount, todayStats } = pageData;
  const { pendingCounts, bookedCounts, doneCounts } = todayStats || {};
  const percentage = availableRoomsCount ? (availableRoomsCount / (availableRoomsCount + checkIn + checkOut)) * 100 : 0;

  return (
    <>
      <Row>
        <Col xs={12} md={3} className="mb-3">
          <Card bg="info no-border">
            <Card.Body style={{ padding: 30 }}>
              <div className="d-flex justify-content-space-between">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <span className="text-white card-heading">
                    {newBookings || 0}
                  </span>
                  <span className="text-white">New Booking</span>
                </div>
                <Image src="./../images/ribbon.svg" width={45} height={45} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <Card bg="success no-border">
            <Card.Body style={{ padding: 30 }}>
              <div className="d-flex justify-content-space-between">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <span className="text-white card-heading">
                  {scheduleRooms || 0}
                  </span>
                  <span className="text-white">Schedule Room</span>
                </div>
                <Image src="./../images/calender.svg" width={45} height={45} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <Card bg="warning no-border">
            <Card.Body style={{ padding: 30 }}>
              <div className="d-flex justify-content-space-between">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <span className="text-white card-heading">
                  {checkIn || 0}
                  </span>
                  <span className="text-white">Check In</span>
                </div>
                <Image src="./../images/checkin.svg" width={45} height={45} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={3} className="mb-3">
          <Card bg="danger no-border">
            <Card.Body style={{ padding: 30 }}>
              <div className="d-flex justify-content-space-between">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <span className="text-white card-heading">
                  {checkOut || 0}
                  </span>
                  <span className="text-white">Check Out</span>
                </div>
                <Image src="./../images/checkout.svg" width={45} height={45} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12} md={4}>
          <Card className="no-border mb-3">
            <Card.Body>
              <div className="d-flex justify-content-center">
                <div style={{ width: 185, height: 185 }}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage.toFixed(0)}%`}
                    strokeWidth={20}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ flexDirection: "column", justifyContent: "center" }}
              >
                <span className="card-heading">
                {availableRoomsCount || 0}
                </span>
                <span>Available Room Today</span>
              </div>
            </Card.Body>
          </Card>
          <Card className="no-border">
            <Card.Body>
              <Card.Title>Booked Room Today</Card.Title>
              <ProgressBar
                variant="progressbar--custom-warning"
                now={pendingCounts || 0}
                label={`${pendingCounts || 0} Pending`}
                className="mb-3"
              />
              <ProgressBar
                variant="progressbar--custom-success"
                now={bookedCounts || 0}
                label={`${bookedCounts || 0} Booked`}
                className="mb-3"
              />
              <ProgressBar
                variant="progressbar--custom-info"
                now={doneCounts || 0}
                label={`${doneCounts || 0} Done`}
                className="mb-3"
              />

              <div className="d-flex justify-content-space-between">
                <div className="d-flex flex-direction-column">
                  <div className="d-flex align-items-center">
                    <span
                      className="me-1"
                      style={{
                        width: 14,
                        height: 14,
                        borderRadius: 100,
                        background: "#F6943A",
                      }}
                    ></span>
                    <span>Pending</span>
                  </div>
                  <strong>{pendingCounts || 0}</strong>
                </div>
                <div className="d-flex flex-direction-column">
                  <div className="d-flex align-items-center">
                    <span
                      className="me-1"
                      style={{
                        width: 14,
                        height: 14,
                        borderRadius: 100,
                        background: "#42AE94",
                      }}
                    ></span>
                    <span>Booked</span>
                  </div>
                  <strong>{bookedCounts || 0}</strong>
                </div>
                <div className="d-flex flex-direction-column">
                  <div className="d-flex align-items-center">
                    <span
                      className="me-1"
                      style={{
                        width: 14,
                        height: 14,
                        borderRadius: 100,
                        background: "#9658C7",
                      }}
                    ></span>
                    <span>Finish</span>
                  </div>
                  <strong>{checkOut || 0}</strong>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={8}>
          <Card className="no-border">
            <Card.Body>
              <Card.Title
                className="mb-0"
                style={{ fontWeight: 600, fontSize: 20 }}
              >
                Reservation Statistic
              </Card.Title>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p className="text-muted mb-0">Toatl Check Ins and Check Outs</p>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={5} md={5}>
                      <p className="mb-0">
                        <span className="card-heading me-2">549</span>
                        <span className="text-muted">Check In</span>
                      </p>
                    </Col>
                    <Col xs={6} md={6}>
                      <p className="mb-0">
                        <span className="card-heading me-2">549</span>
                        <span className="text-muted">Check Out</span>
                      </p>
                    </Col>
                    <Col xs={1} md={1} className="d-flex align-items-center">
                      <Dropdown className="reservation-more-option">
                        <Dropdown.Toggle>
                          <FaEllipsisV />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>Item 1</Dropdown.Item>
                          <Dropdown.Item>Item 2</Dropdown.Item>
                          <Dropdown.Item>Item 3</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <AreaChart
                    width={850}
                    height={405}
                    data={reservationStatistic}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="rgba(19, 98, 252, 1)"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="rgba(255, 110, 90, 1)"
                          stopOpacity={0}
                        />
                      </linearGradient>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#82ca9d"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="90%"
                          stopColor="#82ca9d"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stroke="rgba(19, 98, 252, 1)"
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stroke="rgba(255, 110, 90, 1)"
                      fillOpacity={1}
                      fill="url(#colorPv)"
                    />
                  </AreaChart>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Card className="no-border">
            {pageData &&
            Object.keys(pageData).length > 0 &&
            pageData.newestBookings.length > 0 ? (
              <Card.Body>
                <Calendar style={{ wdith: "100%" }} />
                <div className="d-flex justify-content-space-between mt-5 mb-4">
                  <h5>Newest Booking</h5>
                  <Link to={"/dashboard/booking"} className="custom-text-color">
                    More
                  </Link>
                </div>
                <Row>
                  {pageData.newestBookings.map((item, key) => (
                    <Col xs={12} md={6} key={key} className="mb-3">
                      <Card>
                        <Card.Body>
                          <div className="d-flex">
                            <Image
                              src={item.avatar}
                              width={50}
                              height={50}
                              rounded
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 15,
                              }}
                            >
                              <h6>{item.name}</h6>
                              <p className="custom-text-color fontSize-12">
                                {formateOrderDateTime("date", item.orderDate) +
                                  ", " +
                                  formateOrderDateTime("time", item.orderDate)}
                              </p>
                              <span className="text-muted fontSize-14">
                                Room {item.roomName}, {item.roomCapacity}{" "}
                                Capacity
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            ) : (
              <span className="text-danger p-3">
                No newest booking record found!
              </span>
            )}
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="no-border mb-3">
            <Card.Body>
              <Row>
                <Col xs={6} md={6}>
                  <div className="d-flex align-items-center justify-content-center">
                    <div style={{ width: 130, height: 130 }}>
                      <CircularProgressbar
                        value={percentage}
                        strokeWidth={15}
                        styles={buildStyles({
                          pathColor: "#0ab97a",
                        })}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center flex-direction-column"
                      style={{ paddingLeft: 15 }}
                    >
                      <span style={{ fontSize: 36, fontWeight: 600 }}>60%</span>
                      <span className="text-muted">Check In</span>
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <div className="d-flex align-items-center justify-content-center">
                    <div style={{ width: 130, height: 130 }}>
                      <CircularProgressbar
                        value={percentage}
                        strokeWidth={15}
                        styles={buildStyles({
                          pathColor: "#ffc368",
                        })}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-center flex-direction-column"
                      style={{ paddingLeft: 15 }}
                    >
                      <span style={{ fontSize: 36, fontWeight: 600 }}>40%</span>
                      <span className="text-muted">Check Out</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="no-border">
            <Card.Body>
              {pageData &&
              Object.keys(pageData).length > 0 &&
              pageData.latestReviews.length > 0 ? (
                <div>
                  <Row className="mb-3">
                    <Col xs={8} md={6}>
                      <h5>Latest Customer Review</h5>
                    </Col>
                    <Col xs={4} md={6} style={{ textAlign: "right" }}>
                      <Button
                        variant="danger"
                        as={Link}
                        to={"/dashboard/reviews"}
                      >
                        View All
                      </Button>
                    </Col>
                  </Row>

                  {pageData.latestReviews.map((review, key) => (
                    <Row
                      style={{
                        borderBottom: "1px solid #e5e5e5",
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      key={key}
                    >
                      <Col xs={2} md={2}>
                        <Image
                          src={review?.userId?.avatar}
                          width={80}
                          height={80}
                          rounded
                        />
                      </Col>
                      <Col xs={7} md={7}>
                        <strong>{review?.userId?.name}</strong>
                        <br />
                        <span className="text-muted">
                          Posted on {formatReviewDateTime(review?.reviewDate)}
                        </span>
                        <p style={{ fontSize: 13 }}>{review?.comment}</p>
                      </Col>
                      <Col
                        xs={3}
                        md={3}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <Rating
                          initialRating={review.rating}
                          readonly
                          emptySymbol={<AiOutlineStar />}
                          fullSymbol={
                            <AiFillStar style={{ color: "#FFBC39" }} />
                          }
                        />

                        <div className="d-flex mt-2">
                          <Button variant="success" className="me-2">
                            <TiTick />
                          </Button>
                          <Button variant="danger">
                            <RxCrossCircled />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
              ) : (
                <span className="text-danger">
                  No latest customer review found!
                </span>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
