import { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  Row,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { API_BASE_URL, API_REQUEST_HEADER } from "../../endpoints";
import axios from "axios";
import { doUpload } from "../../helpers/uploads";
import { apiPatchWithAuth } from "../../hooks/authService";

export default function Settings() {
  const [alertMessage, setAlertMessage] = useState({
    type: "danger",
    message: "",
  });
  const [alertMessage2, setAlertMessage2] = useState({
    type: "danger",
    message: "",
  });
  const [alertMessage3, setAlertMessage3] = useState({
    type: "danger",
    message: "",
  });
  const [emailFormValidated, setEmailFormValidated] = useState(false);
  const [passwordFormValidated, setPasswordFormValidated] = useState(false);
  const [personalFormValidated, setPersonalFormValidated] = useState(false);
  let user = JSON.parse(localStorage.getItem("user", {}));
  const [emailFormData, setEmailFormData] = useState({
    currentEmail: "",
    newEmail: "",
    newEmailAgain: "",
  });
  const [passwordFormData, setPasswordFormData] = useState({
    currentPassword: "",
    newPassword: "",
    newPasswordAgain: "",
  });
  const [personalFormData, setPersonalFormData] = useState({
    name: user?.name,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    dob: user?.dob
      ? new Date(user?.dob).toLocaleDateString("en-GB")
      : new Date(),
    address: user?.address,
    avatar: user?.avatar ? user?.avatar : "./../images/default-profile.jpg",
    newAvatar: null,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setEmailFormValidated(true);

    if (emailFormData.currentEmail !== user.email) {
      setAlertMessage({
        type: "danger",
        message: "Email does't match, Invalid email address.",
      });
      return;
    }

    if (emailFormData.newEmail !== emailFormData.newEmailAgain) {
      setAlertMessage({
        type: "danger",
        message: "Again email does't match to new email.",
      });
      return;
    }

    axios
      .patch(
        `${API_BASE_URL}users/${user?.id}`,
        { email: emailFormData.newEmail },
        API_REQUEST_HEADER()
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response?.data));
          setEmailFormData({
            currentEmail: "",
            newEmail: "",
            newEmailAgain: "",
          });
          setEmailFormValidated(false);
          console.log("before alert!!!");
          setAlertMessage({
            type: "success",
            message: "New Email updated successfully!",
          });
        }
      })
      .catch((err) => {
        setAlertMessage({
          type: "danger",
          message: err?.response?.data?.message,
        });
      });
  };

  const handlePasswordFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setPasswordFormValidated(true);

    /*if (emailFormData.currentEmail !== user.email) {
            setAlertMessage({ type: 'danger', message: "Email does't match, Invalid email address." });
            return;
        }*/

    if (passwordFormData.newPassword !== passwordFormData.newPasswordAgain) {
      setAlertMessage2({
        type: "danger",
        message: "Again password does't match to new password.",
      });
      return;
    }

    axios
      .patch(
        `${API_BASE_URL}users/${user?.id}`,
        { password: passwordFormData.newPassword },
        API_REQUEST_HEADER()
      )
      .then((response) => {
        if (response.status === 200) {
          setPasswordFormData({
            currentPassword: "",
            newPassword: "",
            newPasswordAgain: "",
          });
          setPasswordFormValidated(false);
          setAlertMessage2({
            type: "success",
            message: "New Password updated successfully!",
          });
        }
      })
      .catch((err) => {
        setAlertMessage2({
          type: "danger",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    try {
      const result = await doUpload(file);
      setPersonalFormData({ ...personalFormData, newAvatar: result });
    } catch (error) {
      setPersonalFormData({ ...personalFormData, newAvatar: null });
      console.error(error);
    }
  };

  const handlePersonalFormSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setPersonalFormValidated(true);

    let requestBody = { ...personalFormData };
    if (requestBody.newAvatar !== null) {
      requestBody.avatar = requestBody.newAvatar;
    }
    delete requestBody.newAvatar;

    try {
      // Use apiPatchWithAuth function for automatic token refresh
      const response = await apiPatchWithAuth(
        `${API_BASE_URL}users/${user?.id}`,
        requestBody
      );

      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(response.data));
        user = response.data; // Make sure to set user properly in your state
        setPersonalFormValidated(false);
        setAlertMessage3({
          type: "success",
          message: "Personal information updated successfully!",
        });
      }
    } catch (err) {
      setAlertMessage3({
        type: "danger",
        message:
          err?.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <Form
            noValidate
            validated={personalFormValidated}
            onSubmit={handlePersonalFormSubmit}
            encType="multipart/form-data"
          >
            <Card style={{ borderRadius: 5 }}>
              <Card.Header>Personal Information</Card.Header>
              <Card.Body>
                {alertMessage3 && alertMessage3.message && (
                  <Alert variant={alertMessage3.type} dismissible>
                    {alertMessage3.message}
                  </Alert>
                )}
                <div className="d-flex mb-3">
                  {personalFormData.newAvatar ? (
                    <Image
                      src={`${personalFormData.newAvatar}`}
                      width={150}
                      height={170}
                    />
                  ) : (
                    <Image
                      src={`${
                        user?.avatar
                          ? user?.avatar
                          : "/images/default-profile.jpg"
                      }`}
                      width={150}
                      height={170}
                    />
                  )}
                  <div
                    className="d-flex ml-2"
                    style={{ flexDirection: "column" }}
                  >
                    <p>
                      Max file size is 5MB, Minimum dimension: 150x150 And
                      Suitable files are .jpg & .png
                    </p>
                    <label
                      htmlFor="fileInput"
                      className="btn btn-light custom-file-upload"
                      style={{ width: "170px" }}
                    >
                      <FormControl
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                      />
                      Upload Image
                    </label>
                    {personalFormData.newAvatar && (
                      <Button
                        variant="danger"
                        className="bg-custom-variant mt-3"
                        style={{ width: 170 }}
                        onClick={() =>
                          setPersonalFormData({
                            ...personalFormData,
                            newAvatar: null,
                          })
                        }
                      >
                        Remove Image
                      </Button>
                    )}
                  </div>
                </div>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel>Full Name</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Ali Hassan"
                        required
                        value={personalFormData.name}
                        onChange={(e) =>
                          setPersonalFormData({
                            ...personalFormData,
                            name: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Email Address</FormLabel>
                      <FormControl
                        type="email"
                        placeholder="alitufan@gmail.com"
                        required
                        value={personalFormData.email}
                        onChange={(e) =>
                          setPersonalFormData({
                            ...personalFormData,
                            email: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Phone number</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="+01 4561 3214"
                        required
                        value={personalFormData.phoneNumber}
                        onChange={(e) =>
                          setPersonalFormData({
                            ...personalFormData,
                            phoneNumber: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Date of Birth</FormLabel>
                      {console.log("Personal FormData: ", personalFormData)}
                      <ReactDatePicker
                        className="form-control"
                        placeholderText="03 Jan 1990"
                        selected={
                          personalFormData.dob instanceof Date &&
                          !isNaN(personalFormData.dob)
                            ? personalFormData.dob
                            : null
                        }
                        onChange={(date) => {
                          // Ensure the date is valid before setting it
                          if (date instanceof Date && !isNaN(date)) {
                            setPersonalFormData({
                              ...personalFormData,
                              dob: date,
                            });
                          } else {
                            // Handle invalid date case if needed
                            console.error("Invalid date selected");
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <FormGroup>
                      <FormLabel>Address</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="8800 Orchard Lake Road, Suite 180 Farmington Hills, U.S.A."
                        required
                        value={personalFormData.address}
                        onChange={(e) =>
                          setPersonalFormData({
                            ...personalFormData,
                            address: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <Button
                      variant="danger"
                      className="bg-custom-variant"
                      type="submit"
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Col>

        <Col xs={12} md={6} className="mb-3">
          <Form
            noValidate
            validated={emailFormValidated}
            onSubmit={handleSubmit}
          >
            <Card style={{ borderRadius: 5 }}>
              <Card.Header>Change Email</Card.Header>
              <Card.Body>
                {alertMessage && alertMessage.message && (
                  <Alert variant={alertMessage.type} dismissible>
                    {alertMessage.message}
                  </Alert>
                )}
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel>Current Email</FormLabel>
                      <FormControl
                        type="email"
                        required
                        placeholder="Current Email"
                        value={emailFormData.currentEmail}
                        onChange={(e) =>
                          setEmailFormData({
                            ...emailFormData,
                            currentEmail: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Email is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <FormLabel>New Email</FormLabel>
                      <FormControl
                        type="email"
                        required
                        placeholder="New Email"
                        value={emailFormData.newEmail}
                        onChange={(e) =>
                          setEmailFormData({
                            ...emailFormData,
                            newEmail: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        New email is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12} className="mb-3">
                    <FormGroup>
                      <FormLabel>New Email Again</FormLabel>
                      <FormControl
                        type="email"
                        required
                        placeholder="New Email Again"
                        value={emailFormData.newEmailAgain}
                        onChange={(e) =>
                          setEmailFormData({
                            ...emailFormData,
                            newEmailAgain: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Again email is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Button
                      variant="danger"
                      className="bg-custom-variant"
                      type="submit"
                    >
                      Change Email
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Col>

        <Col xs={12} md={6} className="mb-3">
          <Form
            noValidate
            validated={passwordFormValidated}
            onSubmit={handlePasswordFormSubmit}
          >
            <Card style={{ borderRadius: 5 }}>
              <Card.Header>Change Password</Card.Header>
              <Card.Body>
                {alertMessage2 && alertMessage2.message && (
                  <Alert variant={alertMessage2.type} dismissible>
                    {alertMessage2.message}
                  </Alert>
                )}
                <Row>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Current Password</FormLabel>
                      <FormControl
                        type="password"
                        placeholder="Current Password"
                        required
                        value={passwordFormData.currentPassword}
                        onChange={(e) =>
                          setPasswordFormData({
                            ...passwordFormData,
                            currentPassword: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>New Password</FormLabel>
                      <FormControl
                        type="password"
                        placeholder="New Password"
                        required
                        value={passwordFormData.newPassword}
                        onChange={(e) =>
                          setPasswordFormData({
                            ...passwordFormData,
                            newPassword: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <FormGroup>
                      <FormLabel>New Password Again</FormLabel>
                      <FormControl
                        type="password"
                        placeholder="New Password Again"
                        required
                        value={passwordFormData.newPasswordAgain}
                        onChange={(e) =>
                          setPasswordFormData({
                            ...passwordFormData,
                            newPasswordAgain: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={12}>
                    <Button
                      variant="danger"
                      className="bg-custom-variant"
                      type="submit"
                    >
                      Change Password
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Col>

        <Col xs={12} md={6} className="mb-3">
          <Card style={{ borderRadius: 5 }}>
            <Card.Header>Forgot Password</Card.Header>
            <Card.Body>
              <p>
                Enter the email of your account to reset password. Then you will
                receive a link to email to reset the password.If you have any
                issue about reset password
              </p>
              <Row>
                <Col xs={12} md={12} className="mb-3">
                  <FormGroup>
                    <FormLabel>Email Address</FormLabel>
                    <FormControl
                      type="email"
                      placeholder="Enter email address"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Button variant="danger" className="bg-custom-variant">
                    Recover Password
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
