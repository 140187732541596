import { useEffect, useState } from "react";
import { Alert, Button, Form, FormGroup, FormLabel, FormSelect, Modal } from "react-bootstrap";
import { API_BASE_URL } from "../../../endpoints";
import axios from "axios";
import { apiPatchWithAuth } from "../../../hooks/authService";

const EditModal = ({ show, onHide, selectedItem }) => {
    const [apiResponse, setApiResponse] = useState({});
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    const [validated, setValidated] = useState(false);
    const [formModal, setFormModal] = useState({
        status: selectedItem?.status
    });

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        apiPatchWithAuth(`${API_BASE_URL}bookings/${selectedItem?._id}`, formModal, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 200) {
                setApiResponse({ type: 'success', message: "Booking status updated successfully!" });
            }
            console.log(res);
        }).catch(err => {
            setApiResponse({ type: 'danger', message: err?.response?.data?.message });
        });
    }

    useEffect(() => {
        setFormModal({ ...formModal, status: selectedItem.status });
    }, [selectedItem])

    return (

        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
        >
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Booking Status
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
                    <FormGroup>
                        <FormLabel>Status</FormLabel>
                        <FormSelect required value={formModal.status} onChange={(e) => setFormModal({ ...formModal, status: e.target.value })}>
                            <option value="new">New</option>
                            <option value="pending">Pending</option>
                            <option value="booked">Booked</option>
                            <option value="canceled">Canceled</option>
                            <option value="refund">Refund</option>
                            <option value="done">Done</option>
                        </FormSelect>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="danger">Update Booking</Button>
                </Modal.Footer>
            </Form>
        </Modal >
    );
}

export default EditModal;