import "bs5-lightbox";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import HeadingUnderline from "./HeadingUnderline";
import { connect } from "react-redux";
import Rating from "react-rating";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const Trending = ({ trending, searchResults }) => {
  const navigate = useNavigate();
  const hotelsToDisplay = (searchResults && searchResults.length > 0) ? searchResults : trending;

  const handleNavigation = () => {
    navigate("/all-hotels");
  };

  return (
    <div>
      <h5 className="mb-3 text-center">Trending</h5>
      <HeadingUnderline />
      <div className="d-flex justify-content-center mb-3">
        <div className="d-flex flex-wrap justify-content-center">
          <Button
            variant="light"
            className="me-2 mb-2"
            onClick={handleNavigation}
          >
            Hotel
          </Button>
          {/* <Button variant="light" className="me-2 mb-2">
            Activity
          </Button>
          <Button variant="light" className="me-2 mb-2">
            Rental
          </Button>
          <Button variant="light" className="me-2 mb-2">
            Car
          </Button> */}
          <Button variant="light" className="me-2 mb-2">
            Yacht
          </Button>
        </div>
      </div>
      <Row className="mb-3">
        {hotelsToDisplay &&
          hotelsToDisplay.length > 0 &&
          hotelsToDisplay.map((hotel, key) =>
            key < 8 ? (
              <Col xs={6} sm={4} md={3} key={key} className="mb-3">
                <Link
                  to={`/hotel-detail/${hotel._id}`}
                  className="text-decoration-none"
                >
                  <Card className="remove-border-radius">
                    <Card.Img
                      variant="top"
                      className="test"
                      src={hotel.thumbnail}
                      style={{
                        minHeight: 200,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                    />
                    <Card.Body>
                      <p className="mb-1 text-muted">
                        {hotel.city}, {hotel?.destinationId?.name}
                      </p>
                      <div
                        className="card-title h5"
                        style={{
                          fontSize: 14,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 3,
                          height: "3rem",
                        }}
                      >
                        {hotel.name}
                      </div>
                      <div className="flex">
                        <Rating
                          readonly
                          initialRating={hotel?.rating}
                          emptySymbol={<AiOutlineStar />}
                          fullSymbol={
                            <AiFillStar style={{ color: "#B0D12B" }} />
                          }
                        />
                        <span> ({hotel.reviews.length}) Reviews</span>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ) : null
          )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  trending: state.landingPagesInitialState.trending || [], // Ensure trending has a default value
  searchResults: state.landingPagesInitialState.searchResults || [] // Set default as an empty array
});


export default connect(mapStateToProps)(Trending);