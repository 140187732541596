import Cookies from 'js-cookie';
export const API_BASE_URL = 'https://backend.travelpin.com.pk/v1/';
//export const API_BASE_URL = 'http://ec2-52-23-203-173.compute-1.amazonaws.com:8080/v1/';
export const REFRESH_TOKEN_ENDPOINT = API_BASE_URL + 'auth/refresh-token';


// Function to handle refreshing the token
const refreshToken = async () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const refreshToken = tokens?.refresh?.token;

    if (!refreshToken) {
        throw new Error("No refresh token available");
    }

    try {
        const response = await fetch(REFRESH_TOKEN_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken })
        });

        if (!response.ok) {
            throw new Error("Failed to refresh token");
        }

        const data = await response.json();
        // Update the tokens in localStorage
        localStorage.setItem("tokens", JSON.stringify(data.tokens));

        return data.tokens.access.token; // Return the new access token
    } catch (error) {
        console.error("Error refreshing token:", error);
        throw error; // Rethrow to handle downstream
    }
};

export const API_REQUEST_HEADER = async (hasThumbnail) => {
    // Retrieve the token from cookies
    let token = Cookies.get('access_token'); // Change to match your cookie name

    const isTokenValid = token && new Date() < new Date(Cookies.get('token_expiry')); // Update according to how you store expiry

    if (!isTokenValid && Cookies.get('refresh_token')) {
        try {
            // If token is not valid, attempt to refresh it
            token = await refreshToken();
        } catch (error) {
            console.error("Failed to refresh access token:", error);
            // Handle redirect to login or token expiry here
            return {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': hasThumbnail ? 'multipart/form-data' : 'application/json'
                }
            };
        }
    }

    // Return headers with valid token (either refreshed or existing)
    return {
        headers: {
            'accept': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
            'Content-Type': hasThumbnail ? 'multipart/form-data' : 'application/json'
        }
    };
};

// Endpoints
export const REGISTER_ENDPOINT = API_BASE_URL + 'auth/register';
export const LOGIN_ENDPOINT = API_BASE_URL + 'auth/login';
export const SEND_VERIFICATION_EMAIL = API_BASE_URL + 'auth/send-verification-email'; 
export const VERIFY_EMAIL_ENDPOINT = API_BASE_URL + 'auth/verify-email'; 

// Hotel endpoints
export const GET_HOTELS_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}hotels?userId=${param}&limit=${limit}&page=${page}`;
};

export const CREATE_HOTEL_ENDPOINT = `${API_BASE_URL}hotels`;

export const GET_HOTEL_DETAILS_ENDPOINT = (hotelId) => {
    return `${API_BASE_URL}hotels/${hotelId}`;
};

export const UPDATE_HOTEL_ENDPOINT = (hotelId) => {
    return `${API_BASE_URL}hotels/${hotelId}`;
};

export const DELETE_HOTEL_ENDPOINT = `${API_BASE_URL}hotels`;

// Room Endpoints
export const GET_ROOMS_ENDPOINT = (userId, limit, page) => {
    return `${API_BASE_URL}rooms?userId=${userId}&limit=${limit}&page=${page}`;
};

export const CREATE_ROOM_ENDPOINT = `${API_BASE_URL}rooms`;

export const GET_ROOM_DETAILS_ENDPOINT = (roomId) => {
    return `${API_BASE_URL}rooms/${roomId}`;
};

export const UPDATE_ROOM_ENDPOINT = (roomId) => {
    return `${API_BASE_URL}rooms/${roomId}`;
};

export const DELETE_ROOM_ENDPOINT = (roomId) => {
    return `${API_BASE_URL}rooms/${roomId}`; // Include roomId
};


// Facilities Endpoints (matching the backend)
export const CREATE_FACILITY_ENDPOINT = `${API_BASE_URL}facilities`;

export const GET_FACILITIES_ENDPOINT = (userId, limit, page) => {
  return `${API_BASE_URL}facilities?userId=${userId}&limit=${limit}&page=${page}`;
};

export const GET_FACILITY_DETAILS_ENDPOINT = (facilityId) => {
  return `${API_BASE_URL}facilities/${facilityId}`;
};

export const UPDATE_FACILITY_ENDPOINT = (facilityId) => {
  return `${API_BASE_URL}facilities/${facilityId}`;
};

export const DELETE_FACILITY_ENDPOINT = (facilityId) => {
  return `${API_BASE_URL}facilities/${facilityId}`;
};


export const GET_BOOKNGS_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}bookings?userId=${param}&limit=${limit}&page=${page}`;
};

export const GET_GUEST_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}guests?userId=${param}&limit=${limit}&page=${page}`;
};

export const GET_EMPLOYEE_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}users?userId=${param}&role=employee&limit=${limit}&page=${page}`;
};

export const DELETE_BOOKING_ENDPOINT = `${API_BASE_URL}bookings`;
export const DELETE_GUEST_ENDPOINT = `${API_BASE_URL}users`;
export const DELETE_EMPLOYEE_ENDPOINT = `${API_BASE_URL}users`;
