import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import {
  API_REQUEST_HEADER,
  REGISTER_ENDPOINT,
  SEND_VERIFICATION_EMAIL,
} from "../../endpoints";

export default function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user"); // Default role
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleRegister = async () => {
    const form = document.getElementById("registerForm");
    if (form.checkValidity() === false) {
        setValidated(true);
        return;
    }

    const data = {
        name: `${firstName} ${lastName}`,
        email,
        password,
        role,
    };

    setLoading(true);
    setError(null);

    try {
        // Register user
        const response = await axios.post(REGISTER_ENDPOINT, data, {
            ...await API_REQUEST_HEADER(), // Ensure this is called as a function
            withCredentials: true, // Important for sending cookies
        });
        console.log("Registration response:", response.data);
        const verifyEmailToken = response.data.verifyEmailToken;
        console.log("Verification Email token:", verifyEmailToken);
        const accessToken = response.data.tokens.access.token;
        console.log("Access Token:", accessToken);

        if (verifyEmailToken) {
            const verificationResponse = await axios.post(SEND_VERIFICATION_EMAIL, { email },{
                headers: {
                    'Authorization': `Bearer ${accessToken}`,  // Add the token to the Authorization header
                },
                withCredentials: true,
            }
        );
        console.log("Verification email response:", verificationResponse.data);
            if (verificationResponse.data.message === "Verification email sent") {
                setError("Verification email has been sent. Please check your inbox.");
                navigate(`/verify-email?verificationToken=${verifyEmailToken}`);
            }
        }
    } catch (err) {
        console.error("Error details:", err);
        if (err.response) {
            setError(err.response.data.message || "An error occurred. Please try again.");
        } else {
            setError("Network error. Please check your connection.");
        }
    } finally {
        setLoading(false);
    }
};
  return (
      <div className="singup-inner-wrapper">
          <h3>Sign Up</h3>
          <p>Please fill your information below</p>
          <Form id="registerForm" noValidate validated={validated}>
              {/* First Name */}
              <FormGroup>
                  <FormLabel>First name</FormLabel>
                  <FormControl
                      type="text"
                      className="custom-input-style"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      placeholder="Enter first name"
                  />
                  <Form.Control.Feedback type="invalid">
                      Please enter your first name.
                  </Form.Control.Feedback>
              </FormGroup>
              {/* Last Name */}
              <FormGroup>
                  <FormLabel>Last name</FormLabel>
                  <FormControl
                      type="text"
                      className="custom-input-style"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      placeholder="Enter last name"
                  />
                  <Form.Control.Feedback type="invalid">
                      Please enter your last name.
                  </Form.Control.Feedback>
              </FormGroup>
              {/* Email */}
              <FormGroup>
                  <FormLabel>Email</FormLabel>
                  <FormControl
                      type="email"
                      className="custom-input-style"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Enter email address"
                  />
                  <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                  </Form.Control.Feedback>
              </FormGroup>
              {/* Password */}
              <FormGroup className={"mb-2"}>
                  <FormLabel>Password</FormLabel>
                  <FormControl
                      type="password"
                      className="custom-input-style"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="Enter your password"
                  />
                  <Form.Control.Feedback type="invalid">
                      Please enter a password.
                  </Form.Control.Feedback>
              </FormGroup>
              {/* Role Selection */}
              <FormGroup>
                  <FormLabel>Select Role</FormLabel>
                  <Form.Select
                      className="custom-input-style"
                      onChange={(e) => setRole(e.target.value)}
                      required
                  >
                      <option value="user">Traveller</option>
                      <option value="admin">Hotel/Room Owner</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      Please select a role.
                  </Form.Control.Feedback>
              </FormGroup>

              {/* Error Message */}
              {error && (
                  <Row>
                      <Col auto>
                          <div className="alert alert-danger">{error}</div>
                      </Col>
                  </Row>
              )}

              {/* Submit Button */}
              <div className="d-flex justify-content-end align-items-center mt-3 mb-3">
                  <Button
                      variant="primary"
                      className="btn-next"
                      onClick={handleRegister}
                      type="button" // Prevent form submission
                      disabled={loading} // Disable button during loading
                  >
                      {loading ? "Sending..." : "Next"} &nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
              </div>
          </Form>
          <div className="d-flex justify-content-space-between">
              <span>Already have an account?</span>
              <Link
                  to={"/login"}
                  style={{ textDecoration: "none", color: "#008181" }}
              >
                  Login to your account
              </Link>
          </div>
      </div>
  );
}
