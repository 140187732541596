import { Container, Image, Nav, Navbar } from "react-bootstrap";
import CartIconWithBadge from "./CartIconWithBadge";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Menu = ({ defaultActiveNavbar, toggleNavbarItem }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 992;
  const isHomepage =
    pathname === "/" || pathname === "/about-us" || pathname === "/contact-us";
  const menuTextColor = isHomepage ? "text-white" : "text-dark";

  return (
    <>
      {!isMobile && (
        <Navbar bg={isHomepage ? "transparent" : "custom-menu-bar"} expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <Image
                src={isHomepage ? "./../logo-white.svg" : "./../logo.svg"}
              />
              <Image
                src={
                  isHomepage ? "./../logo-text-white.svg" : "./../logo-text.png"
                }
                style={{ height: "30px", width: "auto", marginLeft: "10px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="secondaryNavbar" />

            <Navbar.Collapse
              id="secondaryNavbar"
              className="right-aligned-navbar-collapse"
            >
              <Nav className="me-auto">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={`nav-link ${
                    pathname === "/" ? "my--active" : menuTextColor
                  }`}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/all-hotels"
                  className={`nav-link ${
                    pathname === "/all-hotels" ? "my--active" : menuTextColor
                  }`}
                >
                  Hotel
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/tour" className={`nav-link ${pathname === '/tour' ? 'my--active' : menuTextColor}`}>Tour</Nav.Link> */}
                <Nav.Link
                  as={Link}
                  to="/about-us"
                  className={`nav-link ${
                    pathname === "/about-us" ? "my--active" : menuTextColor
                  }`}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contact-us"
                  className={`nav-link ${
                    pathname === "/contact-us" ? "my--active" : menuTextColor
                  }`}
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/cart"
                  className={`nav-link ${
                    pathname === "/cart" ? "my--active" : menuTextColor
                  }`}
                >
                  <CartIconWithBadge />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  defaultActiveNavbar: state.landingPagesInitialState.defaultActiveNavbar,
});

const dispatchStateToProps = (dispatch) => ({
  toggleNavbarItem: (newNavbarItem) => {
    dispatch({
      type: "UPDATE_LANDING_PAGES_ACTIVE_NAVBAR",
      payload: newNavbarItem,
    });
  },
});
export default connect(mapStateToProps, dispatchStateToProps)(Menu);
