import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { VERIFY_EMAIL_ENDPOINT } from '../../endpoints';
import { Button } from 'react-bootstrap';

const VerifyEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState(null);

    // Extract token from URL query params
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const verificationToken = query.get('verificationToken');
        if (verificationToken) {
            setToken(verificationToken);
        } else {
            setError("Please check your email for a verification link.");
        }
    }, [location]);

    const verifyEmail = useCallback(async () => {
        console.log("Using token for verification:", token);

        if (!token) {
            setError("No verification token found.");
            return;
        }

        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await axios.post(`${VERIFY_EMAIL_ENDPOINT}?token=${encodeURIComponent(token)}`);
            console.log("Verification response:", response);

            if (response.status === 204) {
                setSuccess("Email verified successfully! You will be redirected to the login page.");
                localStorage.removeItem("verificationToken");
                localStorage.removeItem("userEmail");
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            } else {
                setError("Email verification failed. Please try again.");
            }
        } catch (err) {
            console.error("Error verifying email:", err);
            setError(err?.response?.data?.message || "Error verifying email. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [navigate, token]);

    return (
        <>
            <div>
                {loading && <p>Verifying email...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}
                {success && <p style={{ color: "green" }}>{success}</p>}
                {token ? (
                    <div className='singup-inner-wrapper'>
                        <p>Please click on the Verify Email button to verify your email</p>
                        <Button
                            variant="primary"
                            className="btn-next"
                            onClick={verifyEmail}
                            disabled={loading}
                            style={{ marginBottom: '100px' }}
                        >
                            {loading ? "Verifying..." : "Verify Email"}
                        </Button>
                    </div>
                ) : (
                    <p>Please check your email and click the verification link to verify your account.</p>
                )}
            </div>
        </>
    );
};

export default VerifyEmail;
