import {
  Button,
  Card,
  Col,
  Container,
  FormControl,
  Row,
  Spinner,
} from "react-bootstrap";
import ShowOnMap from "../includes/ShowOnMap";
import Filters from "../includes/Filters";
import { FaList } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../endpoints";
import { useCallback, useEffect, useState } from "react";
import Rating from "react-rating";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import MyPagination from "../dashboard/rooms/room-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const AllHotels = ({ filters, searchResults, resetFilters }) => {
  const [hotelList, setHotelList] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [rangeSelectedValue, setRangeSelectedValue] = useState({ min: 0, max: 9990 });
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [pageLimit] = useState(18);
  const [destAndNameSearch, setDestAndNameSearch] = useState("");
  const [displayView, setDisplayView] = useState("grid");
  const [isLoading, setIsLoading] = useState(false);
  const [sortingOption, setSortingOption] = useState("");

  const sortingOptions = ["Popularity", "Guest rating", "Latest", "Price: low to high", "Price: high to low"];

  // Sort hotels based on selected sorting option
  const sortHotels = useCallback(
    (hotels) => {
      return [...hotels].sort((a, b) => {
        switch (sortingOption) {
          case "Popularity":
            return b.popularity - a.popularity;
          case "Guest rating":
            return b.rating - a.rating;
          case "Latest":
            return new Date(b.createdAt) - new Date(a.createdAt);
          case "Price: low to high":
            return a.priceFrom.price - b.priceFrom.price;
          case "Price: high to low":
            return b.priceFrom.price - a.priceFrom.price;
          default:
            return 0;
        }
      });
    },
    [sortingOption]
  );

  // Apply filters locally
  const applyLocalFilters = useCallback(
    (hotels) => {
      const filtered = hotels.filter((hotel) => {
        const { min, max } = rangeSelectedValue;
        const isWithinPriceRange = hotel.priceFrom?.price >= min && hotel.priceFrom.price <= max;
        const hasRequiredFacilities = !filters.facilities || filters.facilities.every((id) => hotel.facilities.includes(id));
        return isWithinPriceRange && hasRequiredFacilities;
      });
      setFilteredHotels(sortHotels(filtered));
    },
    [filters.facilities, rangeSelectedValue, sortHotels]
  );

  // Fetch and set all hotels
  const getAllHotels = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${API_BASE_URL}landing/all-hotels?limit=${pageLimit}&page=${selectedPageNumber}`);
      if (data.results) fetchHotelDetails(data.results);
    } catch (err) {
      console.error("Error fetching all hotels:", err);
    } finally {
      setIsLoading(false);
    }
  }, [pageLimit, selectedPageNumber]);

  // Fetch details for each hotel
  const fetchHotelDetails = async (hotels) => {
    setIsLoading(true);
    const hotelsWithFacilities = await Promise.all(
      hotels.map(async (hotel) => {
        try {
          const { data } = await axios.post(`${API_BASE_URL}landing/hotel-details`, { hotelId: hotel.id });
          if (data) {
            const { priceFrom, rooms, createdAt } = data;
            const uniqueFacilities = Array.from(new Set(rooms.flatMap((room) => room.facilities?.map((f) => f.facilityId?.id)).filter(Boolean)));
            return { ...hotel, priceFrom, facilities: uniqueFacilities, createdAt };
          }
        } catch (error) {
          console.error(`Error fetching details for hotel ${hotel.id}`, error);
        }
        return hotel;
      })
    );
    setHotelList(hotelsWithFacilities);
    setFilteredHotels(hotelsWithFacilities);
    setIsLoading(false);
  };

  // Search hotels by destination or name
  const handleDestAndNameBtnClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${API_BASE_URL}landing/all-hotels?name=${destAndNameSearch}&limit=${pageLimit}&page=${selectedPageNumber}`);
      if (data.results) fetchHotelDetails(data.results);
    } catch (err) {
      console.error("Error during destination/name search:", err);
    } finally {
      setIsLoading(false);
    }
  }, [destAndNameSearch, pageLimit, selectedPageNumber]);

  // Apply server-side filters
  const applyFilters = useCallback(async () => {
    setIsLoading(true);
    const filterParams = [];

    if (filters) {
      filters.cities?.forEach((city) => filterParams.push(`city=${city}`));
      filters.propertyTypes?.forEach((type) => filterParams.push(`propertyType=${type}`));
      filters.roomType?.forEach((type) => filterParams.push(`roomType=${type}`));
      filters.stars?.forEach((star) => filterParams.push(`rating=${star}`));
      filters.status?.forEach((status) => filterParams.push(`status=${status}`));
      if (filters.priceRange) filterParams.push(`minPrice=${filters.priceRange.min}`, `maxPrice=${filters.priceRange.max}`);

      const filterString = filterParams.join("&");

      try {
        const { data } = await axios.get(`${API_BASE_URL}landing/all-hotels?limit=${pageLimit}&page=${selectedPageNumber}&${filterString}`);
        if (data.results) {
          fetchHotelDetails(data.results);
          applyLocalFilters(data.results);
        }
      } catch (error) {
        console.error("Error applying filters:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      getAllHotels();
    }
  }, [filters, pageLimit, selectedPageNumber, applyLocalFilters, getAllHotels]);

  useEffect(() => {
    applyLocalFilters(hotelList);
  }, [applyLocalFilters, hotelList]);

  useEffect(() => {
    getAllHotels();
  }, [getAllHotels]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handlePageChange = (pageNumber) => setSelectedPageNumber(pageNumber);
  
  const handleSortingChange = (option) => {
    setSortingOption(option);
    applyLocalFilters(hotelList); // Re-apply filters and sorting
  };

  const toggleView = (event, type) => {
    event.preventDefault();
    setDisplayView(type);
  };

  return (
    <Container style={{ padding: "20px 10px" }}>
      <Row>
        <Col xs={12} md={3} className="mb-3">
          <Card className="remove-border-radius mb-4">
            <Card.Body>
              <p className="text-muted">Destination or Hotel Name</p>
              <div className="d-grid gap-2">
                <FormControl
                  type="search"
                  placeholder="Search..."
                  value={destAndNameSearch}
                  onChange={(e) => setDestAndNameSearch(e.target.value)}
                />
                <Button
                  variant="danger"
                  size="lg"
                  className="btn-custom-color"
                  onClick={handleDestAndNameBtnClick}
                >
                  <FontAwesomeIcon icon={faSearch} /> Search
                </Button>
              </div>
            </Card.Body>
          </Card>
          <ShowOnMap />
          <Filters
            filters={filters}
            resetFilters={resetFilters}
            rangeSelectedValue={rangeSelectedValue}
            setRangeSelectedValue={setRangeSelectedValue}
          />
        </Col>
        <Col xs={12} md={9}>
          {isLoading ? (
            // Show loading spinner if loading
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" style={{ color: "#008181" }} />
            </div>
          ) : (
            <>
              <Row>
                <Col xs={12} md={8}>
                  <h5>Hotel: {filteredHotels?.length} results found</h5>
                </Col>
                <Col xs={12} md={4}>
                  <div className="d-flex justify-content-end align-items-center">
                    <Link to={"#"} onClick={(e) => toggleView(e, "list")}>
                      <FaList
                        className="me-2"
                        color={displayView === "grid" ? "#e5e5e5" : "#008181"}
                      />
                    </Link>
                    <Link to={"#"} onClick={(e) => toggleView(e, "grid")}>
                      <BsFillGrid3X3GapFill
                        color={displayView === "list" ? "#e5e5e5" : "#008181"}
                      />
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row
                style={{
                  border: "1px solid #e5e5e5",
                  fontSize: 13,
                  padding: 10,
                }}
                className="mt-3"
              >
                <ul className="all-hotels-top-filter-list">
                  {sortingOptions.map((option) => (
                    <li
                      key={option}
                      className="all-hotels-top-filter-list-item"
                      onClick={() => handleSortingChange(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </Row>

              <Row className="mt-3 mb-3 p-0">
                {filteredHotels.map((item, key) => {
                  return displayView === "grid" ? (
                    <Col xs={6} md={4} key={key} className="mb-3">
                      <Link
                        to={`/hotel-detail/${item?.id}`}
                        className="text-decoration-none"
                      >
                        <Card className="no-border-radius">
                          <Card.Img
                            variant="top"
                            src={
                              item.thumbnails &&
                              item.thumbnails.length > 0 &&
                              item.thumbnails[0]
                            }
                            style={{
                              minHeight: 200,
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                          />
                          <Card.Body>
                            <Rating
                              readonly
                              initialRating={item?.rating}
                              emptySymbol={<AiOutlineStar />}
                              fullSymbol={
                                <AiFillStar style={{ color: "#B0D12B" }} />
                              }
                            />
                            <Card.Title
                              style={{
                                fontSize: 14,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.name}
                            </Card.Title>
                            <p className="text-muted fontWeight-12 mb-2">
                              <span className="badge bg-danger">
                                {item.rating ? item?.rating.toFixed(1) : "0.0"}
                                /5
                              </span>
                              <span>
                                {" "}
                                ({item.reviews ? item.reviews.length : 0}{" "}
                                reviews)
                              </span>
                            </p>
                            {item.priceFrom &&
                              Object.keys(item.priceFrom).length > 0 && (
                                <p className="mb-0 text-muted">
                                  From{" "}
                                  <strong>{` ${item.priceFrom.currencySymbol}${item.priceFrom.price}`}</strong>
                                </p>
                              )}
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : (
                    <Col xs={12} md={6} key={key}>
                      <Link
                        to={`/hotel-detail/${item?.id}`}
                        className="text-decoration-none"
                      >
                        <Card className="no-border-radius mb-2 p-0">
                          <Card.Body className="p-0 m-0">
                            <Container className="p-0">
                              <Row>
                                <Col xs={12} md={4}>
                                  <Card.Img
                                    variant="top"
                                    src={
                                      item.thumbnails &&
                                      item.thumbnails.length > 0 &&
                                      item.thumbnails[0]
                                    }
                                    style={{
                                      height: 200,
                                      borderTopRightRadius: 0,
                                      borderTopLeftRadius: 0,
                                    }}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={8}
                                  style={{
                                    verticalAlign: "middle",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      paddingRight: 15,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Rating
                                      readonly
                                      initialRating={item?.rating}
                                      emptySymbol={<AiOutlineStar />}
                                      fullSymbol={
                                        <AiFillStar
                                          style={{ color: "#B0D12B" }}
                                        />
                                      }
                                    />
                                    <Card.Title
                                      style={{
                                        fontSize: 14,
                                        marginBottom: "0.3rem",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        WebkitLineClamp: 3,
                                        height: "3.222rem",
                                      }}
                                    >
                                      {item.name}
                                    </Card.Title>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "#333",
                                        marginBottom: "0.5rem",
                                      }}
                                    >
                                      {item.description} reviews
                                    </p>
                                    <p className="text-muted fontWeight-12 mb-2">
                                      <span className="badge bg-danger">
                                        {item.rating
                                          ? item?.rating.toFixed(1)
                                          : "0.0"}
                                        /5
                                      </span>
                                      <span> {item?.reviews?.length}</span>
                                    </p>
                                    {item.priceFrom &&
                                      Object.keys(item.priceFrom).length >
                                        0 && (
                                        <p className="mb-0 text-muted">
                                          From{" "}
                                          <strong>{` ${item.priceFrom.currencySymbol}${item.priceFrom.price}`}</strong>
                                        </p>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  );
                })}
              </Row>

              <MyPagination
                list={hotelList?.results}
                totalPages={hotelList?.totalPages}
                page={hotelList?.page}
                pageLimit={pageLimit}
                totalResults={hotelList?.totalResults}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  console.log(
    "Filters received in AllHotels from Redux:",
    state.landingPagesInitialState.filters
  );
  return {
    filters: state.landingPagesInitialState.filters || {},
    searchResults: state.landingPagesInitialState.searchResults,
  };
};

const dispatchStateToProps = (dispatch) => ({
  resetFilters: () => {
    dispatch({
      type: "RESET_FILTERS",
      payload: null,
    });
  },
});

export default connect(mapStateToProps, dispatchStateToProps)(AllHotels);
