import { Alert, Button, Col, Form, FormControl, FormGroup, FormLabel, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_REQUEST_HEADER, LOGIN_ENDPOINT } from "../../endpoints";
import { useUser } from "../../userContext";

export default function Login(props) {
    const navigate = useNavigate();
    const { login } = useUser(); // Get login function from context
    const [loggedInAccounts, setLoggedInAccounts] = useState({});
    const [validated, setValidated] = useState(false);
    const hasNoLoggedInAccounts = loggedInAccounts && Object.keys(loggedInAccounts).length === 0;
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');

    useEffect(() => {
        setLoggedInAccounts(JSON.parse(localStorage.getItem("user")));
    }, []);

    const handleNextClick = async (formData) => {
        try {
            const response = await axios.post(LOGIN_ENDPOINT, formData, {
                headers: API_REQUEST_HEADER,
                withCredentials: true,
            });
    
            if (response.data && response.data.user && response.data.tokens) {
                const userRole = response.data.user.role;
                const tokens = response.data.tokens;
    
                // Store tokens in local storage
                localStorage.setItem('accessToken', tokens.access.token);
                localStorage.setItem('refreshToken', tokens.refresh.token);
    
                // Call the login context function
                login(response.data.user, tokens);
    
                // Debugging role
                console.log("User Role: ", userRole);
    
                // Navigate based on role
                if (userRole === 'admin') {
                    navigate("/dashboard/home");
                } else {
                    navigate("/");
                }
            } else {
                console.error("Unexpected response structure: ", response.data);
                setError('Unexpected response from the server.');
            }
        } catch (err) {
            console.error("Error during login: ", err);
            setError(err?.response?.data?.message || 'An error occurred. Please try again.');
        }
    };
    
    
    return (
        <div className="singup-inner-wrapper">
            <h3>Login</h3>
            <p className="mb-2">Please select your account</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form id="loginForm" noValidate validated={validated}>
                {!hasNoLoggedInAccounts &&
                    <div>
                        <FormGroup className="mb-2">
                            <FormLabel>Email</FormLabel>
                            <FormControl
                                type="email"
                                required
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Email is required
                            </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Password</FormLabel>
                            <FormControl
                                type="password"
                                required
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            />
                            <Form.Control.Feedback type="invalid">
                                Password is required
                            </Form.Control.Feedback>
                        </FormGroup>
                    </div>
                }

                {hasNoLoggedInAccounts &&
                    <Row className="choose-account-layout p-2">
                        <Col xs={3} md={3} className="d-flex align-items-center">
                            <Image src="./default-profile.png" width={50} height={50} roundedCircle />
                        </Col>
                        <Col xs={7} md={7} className="d-flex align-items-center">
                            <span>Welcome back, </span>
                            <Link to={"#"} style={{ textDecoration: "none", color: "#008181" }}>{loggedInAccounts?.name}</Link>
                        </Col>
                        <Col xs={2} md={2} className="d-flex align-items-center">
                            <Link to={"#"} style={{ textDecoration: "none", color: "red", fontWeight: 500 }}>Remove</Link>
                        </Col>
                    </Row>
                }

                <Row className="mt-3 mb-5">
                    {hasNoLoggedInAccounts &&
                        <Col xs={12} md={8}>
                            <Link to={"#"}>Login to different account</Link>
                        </Col>
                    }
                    <Col xs={12} md={hasNoLoggedInAccounts ? 4 : 12} style={{ textAlign: 'right' }}>
                        <Button
                            variant="primary"
                            className="btn-next"
                            onClick={() => handleNextClick(formData)}
                            type="button"
                        >
                            Login &nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Row
  className="account-row"
  style={{ borderTop: "1px solid #DFDFDF", paddingTop: 15 }}
>
  <Col xs={6} className="text-left">
    <Link
      to={"/signup"}
      className="account-link"
      style={{ textDecoration: "none", color: "#000" }}
    >
      Don't have an account?
    </Link>
  </Col>
  <Col xs={6} className="text-right">
    <Link
      to={"/signup"}
      className="signup-link"
      style={{ textDecoration: "none", color: "#008181", fontWeight: 500 }}
    >
      Signup
    </Link>
  </Col>
</Row>

        </div>
    );
}
