import AppRoutes from "./routes";
import "./Fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { UserProvider } from "./userContext"; // Import UserProvider
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
      <Router>
      <UserProvider>
        <AppRoutes /> {/* Pass user to AppRoutes for role-based routing */}
        </UserProvider>
      </Router>
  );
};

export default App;
