import axios from 'axios';
import { API_BASE_URL } from '../endpoints';

const REFRESH_TOKEN_ENDPOINT = API_BASE_URL + 'auth/refresh-token'; // Your refresh token endpoint

// Function to refresh the access token
export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        logout();  // If no refresh token, log the user out
        return null;
    }

    try {
        const response = await axios.post(REFRESH_TOKEN_ENDPOINT, { token: refreshToken });
        const newAccessToken = response.data.accessToken;
        localStorage.setItem('accessToken', newAccessToken);  // Save new access token
        return newAccessToken;
    } catch (error) {
        console.error("Error refreshing access token", error);
        logout();  // If refresh fails, log the user out
        return null;
    }
};

// Function to handle GET API requests with automatic token refresh
export const apiGetWithAuth = async (url) => {
    let accessToken = localStorage.getItem('accessToken');

    try {
        // Try making the API request with the access token
        const response = await axios.get(url, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        return response.data;  // Return response if successful
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // If 401 Unauthorized, refresh the access token
            accessToken = await refreshAccessToken();

            if (accessToken) {
                // Retry the API request with the new access token
                const retryResponse = await axios.get(url, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return retryResponse.data;
            }
        }
        throw error;  // If refresh fails, throw the error
    }
};

// Function to handle PATCH/Update requests with automatic token refresh
export const apiPatchWithAuth = async (url, data) => {
    let accessToken = localStorage.getItem('accessToken');

    try {
        // Try making the API request with the access token
        const response = await axios.patch(url, data, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        return response;  // Return the entire response if successful
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // If 401 Unauthorized, refresh the access token
            accessToken = await refreshAccessToken();

            if (accessToken) {
                // Retry the API request with the new access token
                const retryResponse = await axios.patch(url, data, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return retryResponse;  // Return the response on success
            }
        }
        throw error;  // If refresh fails, throw the error
    }
};

// Function to handle POST/Create requests with automatic token refresh
export const apiPostWithAuth = async (url, data) => {
    let accessToken = localStorage.getItem('accessToken');

    try {
        // Try making the API request with the access token
        const response = await axios.post(url, data, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        return response;  // Return the entire response if successful
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // If 401 Unauthorized, refresh the access token
            accessToken = await refreshAccessToken();

            if (accessToken) {
                // Retry the API request with the new access token
                const retryResponse = await axios.post(url, data, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return retryResponse;  // Return the response on success
            }
        }
        throw error;  // If refresh fails, throw the error
    }
};

// Function to handle DELETE requests with automatic token refresh
export const apiDeleteWithAuth = async (url) => {
    let accessToken = localStorage.getItem('accessToken');

    try {
        // Try making the API request with the access token
        const response = await axios.delete(url, {
            headers: { 'Authorization': `Bearer ${accessToken}` },
        });
        return response;  // Return the entire response if successful
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // If 401 Unauthorized, refresh the access token
            accessToken = await refreshAccessToken();

            if (accessToken) {
                // Retry the API request with the new access token
                const retryResponse = await axios.delete(url, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return retryResponse;  // Return the response on success
            }
        }
        throw error;  // If refresh fails, throw the error
    }
};

// Function to handle logout and clear tokens
export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';  // Redirect to login page
};
